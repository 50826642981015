
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import Courseimg from '../Images/f3.jpg'
import choosevideo from '../Images/Why choose us.mp4'
import { FaCheckDouble } from "react-icons/fa";
import '../ComponentsCss/WhyChooseCss.css'
export default function WhychooseUs() {
    return (
        <Container fluid className='whyChooseContainer' >
            <Row>
                <Col md={5}>
                    <video style={{ width: "100%", height: '100%' }} autoPlay loop muted id='video'>
                        <source src={choosevideo} type='video/mp4' />
                    </video>
                </Col>

                <Col md={7}>
                    <h2 style={{ color: '#002147', fontWeight: 'bold', marginTop: 30, fontFamily: 'DM Sans' }}>
                        Why Choose Us
                    </h2>
                    <hr style={{ marginTop: 10, color: '#002147', height: '5px', fontWeight: 'bold', width: '50%' }}></hr>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606', fontFamily: 'DM Sans' }} /> Work Experience
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>We provide guaranteed work experience internships to students/adults with a difference by guaranteeing Quality internship experience only-through matching interns to the correct organization.</p>
                    </p>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606', fontFamily: 'DM Sans' }} /> Live Project Training
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>We will provide either in-house or offline training to all our interns so they can make an instant
                            impact with their host organization from day one.</p>
                    </p>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606', fontFamily: 'DM Sans' }} /> Good Infrastructure
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>We ensure that our students experience a high-end Studio environment with the best standard
                            of Hardware and Computer Labs in the industry. We use the Latest computer Hardware fully
                            equipped to handle the high-end workload of our students.</p>
                    </p>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606', fontFamily: 'DM Sans' }} /> Job-Ready Skills
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>We teach you practical, modular employable skills relevant to what companies want.</p>
                    </p>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606', fontFamily: 'DM Sans' }} /> Modern Facilities
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>We have state-of-the-art hardware and software to impart practical skills to you.</p>
                    </p>

                    <p style={{ marginTop: 10, fontWeight: 'bold', color: ' #666666' }}>
                        <FaCheckDouble style={{ color: '#ffb606' }} /> Individualized Attention
                        <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '22px', fontFamily: 'DM Sans' }}>With a maximum of 12 students in each batch, our students get individual attention and training.</p>
                    </p>
                </Col>
            </Row>
        </Container>

    )
}

