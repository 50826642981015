import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AffliationSlider from './AffliationSlider'

export default function OurAffliations() {
    return (
        <Container fluid style={{ backgroundColor: '#fff' }}>
            <Row>
                <Container style={{ marginTop: 80, marginBottom: 50 }} fli>
                    <Row>
                        <Col md={12}>
                            <h2 style={{ color: '#002147', fontWeight: 'bold', textAlign: 'center' }} >
                                WE WORKED FOR THESE PRECIOUS BRANDS
                            </h2>
                            <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <AffliationSlider />
                        </Col>
                    </Row>

                </Container>
            </Row>
        </Container>
    )
}
