import React, { useState } from 'react'
import { Container, Row, Col, Card, Image, Button, Modal, Form } from 'react-bootstrap'
import AnimationVfx from '../Images/icons/animationvfx.png'
import Interior from '../Images/icons/interiorDesign.png'
import Broadcast from '../Images/icons/broadcast.png'
import GraphicDesign from '../Images/icons/graphicDesign.png'
import VfxComposting from '../Images/icons/vfxcomposting.png'
import VideoEditing from '../Images/icons//videoEditing.png'
import WebDesiging from '../Images/icons/webDesiging.png'
import SortTerm from '../Images/icons/shorttermjob.png'
import swal from 'sweetalert';
import degif from '../Images/Professional course.gif'
import { useQuery } from "@apollo/client";
import '../ComponentsCss/TopCategories.css'
import { MUTATION_CHECK_FEES, MUTATION_ENROLL } from '../graphql/Mutation'
import { useMutation } from '@apollo/client'
import { GET_CHECK_FEES, GET_ENROLL, GET_TOP } from '../graphql/Query'
import Slider from 'react-slick'
import { FaCheckDouble, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import bgtop from '../Images/video/ezgif.com-video-to-gif.gif'

export default function TopCategories() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [validatedEdit, setValidatedEdit] = useState(false)
    const [name, setName] = useState("")
    const [contact, setContact] = useState("")
    const [qualification, setQualification] = useState("")
    const [course, setCourse] = useState("")

    const [validateFees, setValidateFees] = useState(false)
    const [feesName, setFeesName] = useState("")
    const [number, setNumber] = useState("")
    const [courseName, setCourseName] = useState("")

    const [createEnroll] = useMutation(MUTATION_ENROLL, {
        refetchQueries: [
            GET_ENROLL
        ]
    })

    const [createCheckFees] = useMutation(MUTATION_CHECK_FEES, {
        refetchQueries: [
            GET_CHECK_FEES
        ]
    })

    const { data: noneData } = useQuery(GET_TOP);
    function checkFees(data) { }
    function handleSubmitEnroll(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidatedEdit(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidatedEdit(false);
            event.preventDefault();
            createEnroll({
                variables: {
                    "enrollInput": {
                        "name": `${name}`,
                        "contact": `${contact}`,
                        "qualification": `${qualification}`,
                        "course": `${course}`
                    }
                }
            })
            swal({
                title: "Thank you for enquiring ScreenWork Multimedia,",
                text: "we will get back to you soon",
                icon: "success",

            })
            setName('')
            setContact('')
            setQualification('')
            setCourse('')
            handleClose()
        }
    }

    function handleSubmitCheckFees(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidateFees(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidateFees(false);
            event.preventDefault();
            createCheckFees({
                variables: {
                    "checkFeesInput": {
                        "name": `${feesName}`,
                        "number": `${number}`,
                        "courseName": `${courseName}`
                    }
                }
            })
            swal({
                title: "Thank you for enquiring ScreenWork Multimedia,",
                text: "we will get back to you soon",
                icon: "success",

            })
            setFeesName('')
            setNumber('')
            setCourseName('')
            handleClose1()
        }
    }

    const settings = {
        className: "center",
        dots: true,
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <FaChevronRight />,
        prevArrow: <FaChevronLeft />,
        afterChange: function (index) {
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    function editEnroll(ddta) {
        setCourse(ddta)
        handleShow()
    }

    function checkFees(data) {
        setCourseName(data)
        handleShow1()
    }


    return (
        <>
            <Container style={{ marginTop: 30 }} >
                <div className='mx-auto d-block' style={{ backgroundImage: `url(${bgtop})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', textAlign: 'center', height: '180px', width: '100%', paddingTop: '40px' }}>
                    <h2 style={{ textAlign: 'center', color: '#ffffff', fontWeight: 'bold', marginTop: 30 }} >
                        OUR ALL-TOP-CLASS COURSES
                    </h2>
                </div>
                {/* <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2> */}

                <Container style={{ marginTop: "30px" }} fluid>
                    <Row style={{ marginTop: "10px" }}>
                        <Col md={12}>
                            <Slider {...settings}>
                                {noneData &&
                                    noneData.getTopCourse.map((cData) => (
                                        <div>
                                            <Card style={{ borderWidth: "0px", }} className='topcard'>
                                                <Card.Body>
                                                    <h2
                                                        style={{
                                                            color: "#002147",
                                                            fontWeight: "bold",
                                                            fontFamily: "DM Sans",
                                                        }}
                                                    >
                                                        {cData.courseName}
                                                    </h2>
                                                    <p
                                                        style={{
                                                            marginTop: 10,
                                                            fontWeight: "bold",
                                                            fontSize: 20,
                                                            color: " #ffb606",
                                                            fontFamily: "DM Sans",
                                                        }}
                                                    >
                                                        About this Course
                                                    </p>
                                                    <p
                                                        style={{
                                                            color: " #666666",
                                                            fontWeight: "bold",
                                                            fontFamily: "DM Sans",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {cData.description}
                                                    </p>
                                                    <p
                                                        style={{
                                                            marginTop: 20,
                                                            fontWeight: "bold",
                                                            fontSize: 15,
                                                            color: "#002147",
                                                            fontFamily: "DM Sans",
                                                        }}
                                                    >
                                                        LEARNING OUTCOMES
                                                    </p>
                                                    {cData.points.map((outData) => (
                                                        <p
                                                            style={{
                                                                marginTop: 10,
                                                                fontWeight: 400,
                                                                fontSize: "13px",
                                                                color: " #666666",
                                                                fontFamily: "DM Sans",
                                                            }}
                                                        >
                                                            <FaCheckDouble style={{ color: "#ffb606" }} />
                                                            {outData}
                                                        </p>
                                                    ))}
                                                    <Col>
                                                        <Button
                                                            style={{
                                                                maxWidth: "50%",
                                                                minWidth: "37%",
                                                                height: 50,
                                                                color: "#fff",
                                                                fontWeight: "bold",
                                                                marginTop: 10,
                                                                fontFamily: "DM Sans",
                                                            }}
                                                            variant="warning"
                                                            onClick={() => editEnroll(cData.courseName)}
                                                        >
                                                            Enroll Now
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                maxWidth: "50%",
                                                                minWidth: "37%",
                                                                height: 50,
                                                                color: "#fff",
                                                                fontWeight: "bold",
                                                                marginTop: 10,
                                                                fontFamily: "DM Sans",
                                                                backgroundColor: "#3498db",
                                                                marginLeft: 10,
                                                            }}
                                                            onClick={() => checkFees(cData.courseName)}
                                                        >
                                                            Check Fees
                                                        </Button>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                            </Slider>
                        </Col>

                    </Row>
                </Container>
                {/* <SlickSLider /> */}
            </Container>
            {/*                            
                                 <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                ANIMATION & VFX (16 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px' }}>
                                                This program focuses on providing the required knowledge to start your career in the spectacular
                                                Media & Entertainment Industry. It works as a stepping stone of your professional journey.
                                                Now-a-days, Animation and VFX are an integral part of Movies, Web series, TVCs and many other
                                                broadcasting formats. They help to narrate the storytelling on an enhanced way. It had become a
                                                must tool of every Director to properly execute their conceived ideas into reality.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: 13, color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Job-oriented, industry centric curriculum
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: 13, color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Hands-on practical training using latest tools and software
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: 13, color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Certified faculty
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: 13, color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Exposure to industry interaction and workshops
                                            </p>
                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll(' ANIMATION & VFX (16 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees(' ANIMATION & VFX (16 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div> 
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                BROADCAST GRAPHICS (12 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px' }}>
                                                Broadcast Graphic is a comprehensive course that trains you in all aspects of channel packaging
                                                and makes you a job-ready professional. The course focuses on the design aspect of digital
                                                communication for films, TV, and the digital media industry.
                                                You will learn the fundamentals & techniques of graphic animation, live footage, music, sound,
                                                electronic and interactive media, and unconventional media tools to create engaging motion
                                                visuals for short-form visual communications, entertainment, edutainment, commercials,
                                                broadcast, and corporate communications.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Drawing, Perspective & Design Principles.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Printing parameters, Creative & Aesthetics Skills.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Color Theory, Layout Design.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> 2D and 3D Motion Graphics.
                                            </p>
                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll(' BROADCAST GRAPHICS (12 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees(' BROADCAST GRAPHICS (12 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                GRAPHIC DESIGN (6 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                Graphic Designing is a combination of text, images, designs & illustrations in a presentable
                                                layout for print. It has a wide area of requirements like a design for art, illustration, advertising,
                                                photography, image editing, newspaper, magazines, displays, etc. Our course caters to all
                                                different forms of print-related activities, where a student learns from basics to the advance
                                                forms of graphic designs. In this course, we cover the world's best printing tools and
                                                technologies with a strong focus on design.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Drawing & Perspective.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Printing parameters & Design Principles.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Creative Visualization & Aesthetics Skills.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Color Theory, Layout Design.
                                            </p>
                                            {/* <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Required back-end coding for smooth functioning of website.
                                            </p> */}
            {/* <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('GRAPHIC DESIGN (6 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('GRAPHIC DESIGN (6 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                VFX COMPOSITING & EDITING (12 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                This course provides an entry into the Visual Effects world. The course starts with the
                                                fundamentals of art, design & CG. Then exposing the students to 3D computer graphics &
                                                further strengthening their skills in tracking, rotoscoping & compositing, which form the base of
                                                visual effects.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Increase design and visualization skills
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Learn complete Production and Post-Production Pipeline

                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Continuous evaluation through examination, competitions, and activities
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Placement support
                                            </p>
                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('VFX COMPOSITING & EDITING (12 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('VFX COMPOSITING & EDITING (12 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                VIDEO EDITING (6 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                Video Editing Course is highly focused on in-depth professional training.The qualification is
                                                designed to prepare students for careers in various concentrations within the specialization of
                                                graphic design, video post-production, sound and video visual effects, optimization for social
                                                media, and content development.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Increase design and visualization skills
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Learn various Multimedia tools

                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Continuous evaluation through competitions and activities
                                            </p>
                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('VIDEO EDITING (6 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('VIDEO EDITING (6 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                UI/UX & WEB DESIGNING (6-18 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                Web designing typically includes a combination of text, images, animation, audio-video & other
                                                interactive forms of media, embedded in the presentable form for web/internet. This program
                                                exposes you to the art & technology of creating web templates, web banners, static as well as
                                                interactive flash web pages, and animated e-presentations.                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Complete website design.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Critical functions to handle all online processes.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Graphics creation as per client requirement.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> UI (User Interface) and UX (User Experience).
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} />Required back-end coding for smooth functioning of website.
                                            </p>
                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('UI/UX & WEB DESIGNING (6-18 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('UI/UX & WEB DESIGNING (6-18 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                INTERIOR DESIGN (12-24 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                This course has been developed to provide Foundation knowledge in Interior Designing. In this
                                                course, you will learn to design Home & Office interiors with ease. More emphasis is laid on
                                                sheet working.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Techniques to read the 2D drawings.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Tips and tricks to manipulate 3D models, textures and lighting as per the requirement.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Industry standard rendering techniques for photo realistic Architectural views.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Creation of 3D Architectural Walkthroughs.
                                            </p>

                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll(' INTERIOR DESIGN (12-24 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees(' INTERIOR DESIGN (12-24 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                DIGITAL MARKETING (1.5-16 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                Digital marketing refers to advertising delivered through digital channels such as search engines, websites,
                                                social media, email, and mobile apps. Learn from Google certified professionals.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Increase technical and visualization skills.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Learn complete digital marketing pipeline.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Placement support.
                                            </p>

                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('DIGITAL MARKETING (1.5-16 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('DIGITAL MARKETING (1.5-16 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ borderWidth: '0px' }}>
                                        <Card.Body>
                                            <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }}>
                                                SHORT TERM COURSES (1.5-6 MONTHS)
                                            </h2>
                                            <p style={{ marginTop: 10, fontWeight: 'bold', fontSize: 20, color: ' #ffb606', fontFamily: 'DM Sans' }}>
                                                About this Course
                                            </p>
                                            <p style={{ color: ' #666666', fontWeight: 'bold', fontFamily: 'DM Sans', fontSize: '13px', }}>
                                                Enhance your Skills by learning the Basics of Designing and Latest in-demand Software used in Animation,
                                                Multimedia, Gaming, and Web designing.
                                                If you are new to this Field & Looking to Learn Specific Software along with your studies, or a professional
                                                looking to Sharpen your Skills you can also apply Short Term Course. ScreenWork offers quick, Short-Term
                                                Courses to the Students & Working Professionals who wish to sharpen their Skills in one or more
                                                Software.
                                            </p>
                                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 15, color: '#002147', fontFamily: 'DM Sans' }}>
                                                PROGRAM HIGHLIGHTS:
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Increase technical skills.
                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Learn about software which you choose.

                                            </p>
                                            <p style={{ marginTop: 10, fontWeight: 400, fontSize: '13px', color: ' #666666', fontFamily: 'DM Sans' }}>
                                                <FaCheckDouble style={{ color: '#ffb606' }} /> Placement support.
                                            </p>

                                            <Col>
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }} variant="warning" onClick={() => editEnroll('SHORT TERM COURSES (1.5-6 MONTHS)')}> Enroll Now </Button>{' '}
                                                <Button style={{ maxWidth: '50%', minWidth: '37%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans', backgroundColor: '#3498db' }} onClick={() => checkFees('SHORT TERM COURSES (1.5-6 MONTHS)')}> Check Fees </Button>{' '}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </div> */}


            {/* </Slider> */}
            {/* </Col> */}
            {/* <Col md={4}>
                            <Image className='mx-auto d-block' src={degif} style={{ height: '70%', width: '90%' }}></Image>
                        </Col> */}
            {/* </Row> */}
            {/* //     </Container> */}
            {/* // </Container> */}

            {/* {/* <Container style={{ marginTop: "30px" }} fluid>
          <Row style={{ marginTop: "10px" }}>
            <Col md={8}>
              <Slider {...settings}>
                {noneData &&
                  noneData.getPopularCourse.map((cData) => (
                    <div>
                      <Card style={{ borderWidth: "0px", height: 470 }}>
                        <Card.Body>
                          <h2
                            style={{
                              color: "#002147",
                              fontWeight: "bold",
                              fontFamily: "DM Sans",
                            }}
                          >
                            {cData.courseName}
                          </h2>
                          <p
                            style={{
                              marginTop: 10,
                              fontWeight: "bold",
                              fontSize: 20,
                              color: " #ffb606",
                              fontFamily: "DM Sans",
                            }}
                          >
                            About this Course
                          </p>
                          <p
                            style={{
                              color: " #666666",
                              fontWeight: "bold",
                              fontFamily: "DM Sans",
                              fontSize: "13px",
                            }}
                          >
                            {cData.description}
                          </p>
                          <p
                            style={{
                              marginTop: 20,
                              fontWeight: "bold",
                              fontSize: 15,
                              color: "#002147",
                              fontFamily: "DM Sans",
                            }}
                          >
                            LEARNING OUTCOMES
                          </p>
                          {cData.points.map((outData) => (
                            <p
                              style={{
                                marginTop: 10,
                                fontWeight: 400,
                                fontSize: "13px",
                                color: " #666666",
                                fontFamily: "DM Sans",
                              }}
                            >
                              <FaCheckDouble style={{ color: "#ffb606" }} />
                              {outData}
                            </p>
                          ))}
                          <Col>
                            <Button
                              style={{
                                maxWidth: "50%",
                                minWidth: "37%",
                                height: 50,
                                color: "#fff",
                                fontWeight: "bold",
                                marginTop: 10,
                                fontFamily: "DM Sans",
                              }}
                              variant="warning"
                              onClick={() => editEnroll(cData.courseName)}
                            >
                              Enroll Now
                            </Button>
                            <Button
                              style={{
                                maxWidth: "50%",
                                minWidth: "37%",
                                height: 50,
                                color: "#fff",
                                fontWeight: "bold",
                                marginTop: 10,
                                fontFamily: "DM Sans",
                                backgroundColor: "#3498db",
                                marginLeft: 10,
                              }}
                              onClick={() => checkFees(cData.courseName)}
                            >
                              Check Fees
                            </Button>
                          </Col>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
              </Slider>
            </Col>
            <Col md={4}>
              <Image
                className="mx-auto d-block d-none d-lg-block"
                src={degif}
                style={{ height: "87%", width: "100%", marginTop: "2px" }}
              ></Image>
            </Col>
          </Row>
        </Container> */}
            {/* <SlickSLider /> */}



            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedEdit} onSubmit={handleSubmitEnroll}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01" style={{ marginTop: '10px' }}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control required type="text" placeholder='Enter Name' onChange={(e) => setName(e.target.value)} value={name} />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02" style={{ marginTop: '10px' }}>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control required type="number" placeholder='Enter Phone No.' onChange={(e) => setContact(e.target.value)} value={contact} />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: '15px' }}>
                                <Form.Label>Qualification</Form.Label>
                                <Form.Control required as="textarea" placeholder='Enter Your Qualification' onChange={(e) => setQualification(e.target.value)} value={qualification} />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: '15px' }}>
                                <Form.Label>Course</Form.Label>
                                <Form.Control required type="text" placeholder='Enter Course' value={course} disabled />
                            </Form.Group>
                        </Row>
                        <Button className="mx-auto d-block" type="submit" variant="warning" style={{ width: '37%', height: 40, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }}>Apply Now</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validateFees} onSubmit={handleSubmitCheckFees}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01" style={{ marginTop: '10px' }}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control required type="text" placeholder='Enter Name' onChange={(e) => setFeesName(e.target.value)} value={feesName} />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02" style={{ marginTop: '10px' }}>
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control required type="number" placeholder='Enter Mobile No.' onChange={(e) => setNumber(e.target.value)} value={number} />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: '15px' }}>
                                <Form.Label>Course Name</Form.Label>
                                <Form.Control required type="text" placeholder='Enter Course' value={courseName} disabled />
                            </Form.Group>
                        </Row>
                        <Button className="mx-auto d-block" type="submit" variant="warning" style={{ width: '37%', height: 40, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }}>Check Fees</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
