import React, { useState } from 'react'
import { Card, Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { useMutation } from '@apollo/client'
import { Navigate } from 'react-router-dom';
import { ADMIN_LOGIN } from '../../graphql/Mutation';

export default function AdminLogIn() {

    const token = localStorage.getItem('adminToken')
    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorState, setErrorState] = useState(false)


    const [createAdmin, { loading: createAdminLoading, data }] = useMutation(ADMIN_LOGIN, {
        onError(error) {
            setErrorState(true)
        }
    })

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            createAdmin({
                variables: {
                    "userName": `${username}`,
                    "password": `${password}`
                }
            })
        }
    };
    if (data) {
        localStorage.setItem("adminToken", data && data.adminLogin.adminToken)
        localStorage.setItem("adminId", data && data.adminLogin.adminId)
        return <Navigate to="/admindashboard/testimonials" />
    }

    if (token) {
        return <Navigate to="/admindashboard/testimonials" />
    }
    return (
        <>
            <Container>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <h6 style={{ marginTop: 100, textAlign: "center" }}>Admin Login</h6>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>User name</Form.Label>
                                    <Form.Control required type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-6">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </Row>
                            {
                                errorState ?
                                    <h1 style={{ fontSize: '15px', textAlign: 'center', color: 'red', marginTop: '20px' }}>Username & Password not match!!!</h1> : ''
                            }
                            {
                                createAdminLoading ?
                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <Button type="submit" style={{ marginTop: 50 }} className="mx-auto d-block">Login</Button>
                            }
                        </Form>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
        </>
    )
}
