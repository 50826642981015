import Home from "./Components/Home";
import { Routes, Route, Link } from "react-router-dom";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import AdminLogIn from "./Components/Admin/AdminLogIn";
import AdTestimonial from "./Components/Admin/AdTestimonial";
import CheckCourseRequest from "./Components/Admin/CheckCourseRequest";
import EnrollRequest from "./Components/Admin/EnrollRequest";
import FeesRequest from "./Components/Admin/FeesRequest";
import "./App.css";
import NewCourse from "./Components/Admin/NewCourse";
import AllCourse from "./Components/Admin/AllCourses";
import ContactUs from "./Components/ContactUs";
import ContactRequest from "./Components/Admin/ContactRequest";
import Maintenance from "./Components/Maintenance";
import AboutUs from "./Components/AboutUs";
import TermsOfUse from "./Components/TermOfUse";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./Components/PrivacyPolicy";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admindashboard" element={<AdminDashboard />}>
        <Route path="testimonials" element={<AdTestimonial />} />
        <Route path="courcerequest" element={<CheckCourseRequest />} />
        <Route path="enrollrequest" element={<EnrollRequest />} />
        <Route path="feesrequest" element={<FeesRequest />} />
        <Route path="newcourse" element={<NewCourse />} />
        <Route path="allcourse" element={<AllCourse />} />
        <Route path="contactrequest" element={<ContactRequest />} />
      </Route>
      <Route path="/adminlogin" element={<AdminLogIn />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
