import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import gupul from '../Images/Gupul Patel.png'
import anurag from '../Images/Anurag Pandey.png'
import rumi from '../Images/Rumi Patel.png'
import '../ComponentsCss/Ourteam.css'


export default function OurTeam() {
    return (
        <>
            <Container  >
                <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                        <h2 style={{ color: '#002147', fontWeight: 'bold' }} >
                            Experience Team
                        </h2>
                        <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2>
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{ backgroundColor: '#ffb606' }}  >
                <Container > 
                    <Row style={{ backgroundColor: '#ffb606' }} ></Row>
                    <Row className='expBox' > 
                        <Col md={4} style={{ textAlign: 'center' }}>
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={gupul} className="teamimgGupul mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Gupul Patel</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                        (Managing Director)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4} style={{ textAlign: 'center' }} >
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={rumi} className="teamimgRumi mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Rumi Patel</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                        (Placement support)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4} style={{ textAlign: 'center'}}>
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={anurag} className="teamimgAnurag mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Anurag Pandey</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                        (Service Partner)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        {/* <Col md={2} style={{ textAlign: 'center' }}>
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={ankita} className="teamimg mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Ankita Singh</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                       (HR executive)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={2} style={{ textAlign: 'center' }}>
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={ansul} className="teamimg mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Anshul Pandey</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                        (Marketing Executive)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={2} style={{ textAlign: 'center' }}>
                            <Card style={{ backgroundColor: '#ecf0f1', border: 'none ' }}>
                                <Card.Img variant="top" src={Devanshi} className="teamimg mx-auto d-block" />
                                <Card.Body>
                                    <Card.Title style={{ fontWeight: 'bold', fontSize: '18px' }}>Devyanshi Patel</Card.Title>
                                    <Card.Text style={{ fontSize: '15px' }}>
                                        (Interior Designer)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
            </Container>
        </>
    )
}
