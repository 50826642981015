import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Navigation from './Navigation';

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , []);
    return (
        <>
            <Navigation />
            <div className="container mt-5 " style={{fontWeight :"500",fontSize :"15px"}} >
                <h2 className="mb-4 text-center">INTRODUCTION</h2>
                <p>Welcome to the ScreenWork Institute of Animation and Multimedia</p>
                <p>Screenwork Institute of Animation and Multimedia Create Your Bright Future with us, Learn the High-Quality Education in Multimedia.</p>
                <p>Screenwork Institute of Animation and Multimedia is an organization that has a line of premiere training institutes across India along with state-of-the-art production facilities under the brand name Screenwork Institute. Screenwork Institute of Animation and Multimedia provides 100% job Placement support.</p>
                <p>We are committed to creating successful professionals in the field of Animation, Visual Effects (VFX), Graphics, Motion Graphics & Web Design. We provide a launch pad for aspiring artists and creative minds by nurturing their talent and giving them industry exposure.</p>
                <p>Screenwork programs are united by one single focus – to make you the best creatively for an exciting career in your chosen profession. Get a chance to learn industry-grade level animation and other multimedia courses.</p>
                <p>If you are wondering which program is the right fit for you, sign up for our free career counseling session. Choose a program and get started on the path to your bright and successful career!</p>
                <p>Our mission is to fulfill the gap between the needs of the rapidly booming animation, gaming, and visual special effects industry for skilled professionals who fulfill the industry's needs. To ensure the requisite skills and abilities Screenwork students are taught on extremely high-end computer platforms that can handle the demands made on technology by animation and gaming disciplines.</p>
            </div>
            <Footer />
        </>
    );
}

export default AboutUs;
