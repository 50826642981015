import { gql } from "@apollo/client";

export const GET_EVENT = gql`
  query Query {
    getAllEvents {
      id
      title
      description
      eventDate
      eventStartTime
      eventEndTime
      venue
      createdDateTime
      status
    }
  }
`;
export const GET_COURSES = gql`
query GetAllCourse {
  getAllCourse {
    id
    courseName
    description
    learningOutcomes
    teacherName
    studentEnrolled
    duration
    fees
    images
    createdDateTime
    status
    points
    tag
  }
}
`;
export const GET_GALLERY = gql`
  query Query {
    getAllGallery {
      tittle
      imageKey
      description
      category
      createdDateTime
      status
    }
  }
`;
export const GET_PLACEMENT = gql`
  query Query {
    getAllPlacement {
      name
      description
      courseName
      placementClgName
      placementYear
      placementPosition
      createdDateTime
      status
    }
  }
`;
export const GET_ENROLL = gql`
  query Query {
    getEnroll {
      id
      name
      contact
      qualification
      course
      createdDateTime
      status
    }
  }
`;
export const GET_TESTIMONIAL = gql`
  query Query {
    getTestimonial {
      id
      name
      discription
      designation
      companyname
      profileImage
      createdDateTime
      status
    }
  }
`;
export const GET_CHECK_FEES = gql`
  query Query {
    getCheckFees {
      id
      name
      number
      courseName
      createdDateTime
      status
    }
  }
`;

export const GET_CHECK_CONTACTREQUEST = gql`
  query Query {
    getContactus {
      id
      name
      email
      contact
      message
      createdDateTime
      status
    }
  }
`;
export const GET_NONE_OF_THESE = gql`
  query GetNoneofThese {
    getNoneofThese {
      id
      courseName
      description
      learningOutcomes
      teacherName
      studentEnrolled
      duration
      fees
      images
      createdDateTime
      status
      points
      tag
    }
  }
`;

export const GET_BORING = gql`
  query Query {
    getNoBoring {
      id
      courseName
      description
      learningOutcomes
      teacherName
      studentEnrolled
      duration
      fees
      images
      createdDateTime
      status
      points
      tag
    }
  }
`;

export const GET_POPULAR = gql`
  query GetPopularCourse {
    getPopularCourse {
      id
      courseName
      description
      learningOutcomes
      teacherName
      studentEnrolled
      duration
      fees
      images
      createdDateTime
      status
      points
      tag
    }
  }
`;
export const GET_TOP = gql`
query Query {
  getTopCourse {
    id
    courseName
    description
    learningOutcomes
    teacherName
    studentEnrolled
    duration
    fees
    images
    createdDateTime
    status
    points
    tag
  }
}
`;
