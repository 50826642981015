/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import '../ComponentsCss/TopEvents.css'
import overall from '../Images/Copy of overallcreatives logo.png'

export default function TopEvents() {
    return (
        <Container >
            <Row>
                <Col md={12} style={{ marginTop: 20 }}>
                    <h2 style={{ textAlign: 'center', color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }} >
                        OUR ANIMATION STUDIO
                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2>
                    <Container fluid>
                        <Row>
                            <Col md={4}>
                                <Image className='topeventimg mx-auto d-block' src={overall} />
                            </Col>
                            <Col md={8}>
                                <p className='animationStudioText'>
                                    Overall Creatives: Overall Creatives is an Animation Studio. We provide a wide range of expertise in
                                    2d/3d animations, Commercial Explainers videos, Creative Motion graphics, Logo Animations, Brand
                                    Designs, Product Illustrations, and Digital and Matte paintings. With a highly technical skill-set
                                    including 3D modeling, animation, Character Design, Rigging, Caricature design, and 3D Motion
                                    graphics. We use the resources in the best possible way to provide the best output within your
                                    budget requirements
                                </p>
                                <p className='animationStudioText'>
                                    From Animated Explainer Videos to Live action Testimonial Videos. At Overall Creatives,
                                    we take care of all your business needs to generate a successful video marketing campaign with the
                                    best quality and at an affordable price
                                </p>
                                <a href="http://overallcreatives.com/" target="_blank" style={{textDecoration:'none'}}>
                                <Button className='topeventbtn1 mx-auto d-block' style={{ textDecoration: 'none !important', color: '#fff' }}>Visit Website</Button>
                                </a>
                                <a href="https://www.youtube.com/overallcreatives" target="_blank">
                                    <Button className='topeventbtn2 mx-auto d-block' style={{ color: '#fff' }}> View Our Work </Button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            {/* 
            <Card className='eventCard'>
                <Card.Body>
                    <Row>
                        <Col md={6} style={{ paddingBottom: 25 }}>
                            <Image src={e1} className='img-fluid' />
                        </Col>
                        <Col md={6}>
                            <h5 style={{ color: '#002147', fontWeight: 'bold' }}>
                                Graphic and interactive design
                            </h5>

                            <Row>
                                <Col md={3}>
                                    <p style={{ color: ' #666666', fontWeight: 600 }}>
                                        <FaCalendarAlt style={{ marginTop: -4 }} />   15 Oct, 2019
                                    </p>
                                </Col>
                                <Col md={4}>
                                    <p style={{ color: ' #666666', fontWeight: 600 }}>
                                        <FaClock style={{ marginTop: -4 }} />   8:00 AM - 5:00 PM </p>
                                </Col>
                                <Col md={5}>
                                    <p style={{ color: ' #666666', fontWeight: 600 }}>
                                        <FaMap style={{ marginTop: -4 }} />  California, TX 70420
                                    </p>
                                </Col>
                            </Row>

                            <Col md={12} style={{

                                marginTop: 30
                            }}>
                                <Row style={{
                                    backgroundColor: 'rgba(20, 23, 28, 0.1)',
                                    height: 2,
                                    marginBottom: 30
                                }}>

                                </Row>

                            </Col>
                            <p style={{ marginTop: 10, color: ' #666666', fontWeight: 400 }}>

                                Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion be zealously as. Preference inquietude ask
                            </p>



                        </Col>
                    </Row>
                </Card.Body>
            </Card> */}



        </Container>
    )
}


