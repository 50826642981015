import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import '../ComponentsCss/AffliationSlider.css';

import pic1 from '../Images/WhatsApp Image 2022-08-17 at 10.37.13 AM.jpeg';
import pic2 from '../Images/svnu.png';
import pic3 from '../Images/VVLOGO.png';
import pic4 from '../Images/Asset 1.png';
import pic5 from '../Images/Hashtag S LOGO.png';
import pic6 from '../Images/Interior V LOGO.png';
import pic7 from '../Images/Copy of overallcreatives logo.png';


export default function PartnershipSlider() {
    const settings = {
        className: "center",
        infinite: true,
        slidesToShow: 3,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 1500,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Container className='mx-auto d-block' style={{ marginTop: '30px' }}>
                <Slider {...settings}>
                    <div>
                        <Image src={pic1} className='mx-auto d-block' style={{ height: '120px', width: "250px" }} />
                    </div>
                    <div>
                        <Image src={pic2} className='mx-auto d-block' style={{ height: '120px', width: "180px" }} />
                    </div>
                    <div>
                        <Image src={pic3} className='mx-auto d-block' style={{ height: '160px', width: "190px", marginTop: '-20px' }} />
                    </div>
                    <div>
                        <Image src={pic4} className='mx-auto d-block' style={{ height: '100px', width: "200px" }} />
                    </div>
                    <div>
                        <Image src={pic5} className='mx-auto d-block' style={{ height: '140px', width: "220px" }} />
                    </div>
                    <div>
                        <Image src={pic6} className='mx-auto d-block' style={{ height: '150px', width: "200px" }} />
                    </div>
                    <div>
                        <Image src={pic7} className='mx-auto d-block' style={{ height: '100px', width: "250px" }} />
                    </div>
                </Slider>
            </Container>
        </>
    )
}
