import { useState } from 'react';
import { Spinner, Button, Form, Modal } from 'react-bootstrap';
import { MUTATION_UPDATECOURSE } from '../../../graphql/Mutation'
import { GET_COURSES } from '../../../graphql/Query'
import { useMutation } from '@apollo/client'
import { FaPlus, FaMinus } from "react-icons/fa";

function EditCourse(props) {
  const { course, setShowEditModal, showEditModal } = props
  const [courseName, setcourseName] = useState(course.courseName);
  const [description, setDescription] = useState(course.description);
  const [coursetag, setCourseTag] = useState(course.tag);
  const [points, setPoints] = useState(course.points);
  console.log("points", points)

  const [updateCourse, { loading }] = useMutation(MUTATION_UPDATECOURSE, {
    refetchQueries: [
      GET_COURSES
    ]
  })

  function handlePointsChange(index, value) {
    const newPoints = [...points];
    newPoints[index] = value;
    setPoints(newPoints);
  }

  function handleAddPoint() {
    setPoints([...points, ""]);
  }

  function handleRemovePoint(index) {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  }

  const handleClose = () => setShowEditModal(false);
  const handleSubmit = () => {
    updateCourse({
      variables: {
        "courseInput": {
          "id": `${course.id}`,
          "courseName": `${courseName}`,
          "description": `${description}`,
          "points": points,
          "tag": `${course.tag}`,
          "createdDateTime": `${course.createdDateTime}`,
          "status": "active"
        }
      }
    }).then((data) => {
      console.log(data);
      setShowEditModal(false)

    })
  }
  return (
    <>
      <Modal show={showEditModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Course Name</Form.Label>
              <Form.Control style={{ fontSize: 12 }} onChange={(e) => { setcourseName(e.target.value) }} type="text" value={courseName} autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Description</Form.Label>
              <Form.Control style={{ fontSize: 12 }} as="textarea" rows={3} onChange={(e) => { setDescription(e.target.value) }} value={description} />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontWeight: 'bold', fontSize: 12 }}>Tag</Form.Label>
              <Form.Control style={{ fontSize: 12 }} onChange={(e) => { setCourseTag(e.target.value) }} type="text" value={coursetag} autoFocus />
            </Form.Group> */}

            <div className="form-group" style={{ marginTop: 20 }}>
              <label htmlFor="points" style={{ fontWeight: 'bold', fontSize: 12 }}>Points</label>
              {
                points.map((point, index) => (
                  <div className="input-group mb-3" key={index}>
                    <textarea type="text" className="form-control" value={point} onChange={(event) => handlePointsChange(index, event.target.value)} style={{ fontSize: 12 }} />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-danger" onClick={() => handleRemovePoint(index)} disabled={points.length === 1}>
                        <FaMinus />
                      </button>
                    </div>
                  </div>
                ))}
              <button type="button" className="btn btn-outline-primary mb-3" onClick={handleAddPoint}>
                <FaPlus /> Add Point
              </button>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label htmlFor="tags" style={{ fontWeight: 'bold', fontSize: 12 }}>Tag</label>
              <select style={{ fontSize: 12 }} className="form-control" id="tags" value={coursetag} onChange={(e) => { setCourseTag(e.target.value) }}>
                <option value="Boring Degree Courses">No More Boring Degree Courses</option>
                <option value="Popular Courses">Popular Courses</option>
                <option value="All Top Courses">All Top Courses</option>
                <option value="None of these">None of these</option>
              </select>
            </Form.Group>
          </Form>
          {
            loading ?
              <Spinner animation="border" role="status" className="mx-auto d-block">
                <span className="visually-hidden">Loading...</span>
              </Spinner> :
              <Button variant="primary" onClick={handleSubmit} className='mx-auto d-block'>Edit</Button>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditCourse;