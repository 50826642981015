
import React, { useState } from "react";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import EditCourse from "./Modals/EditCourse";
import { useQuery } from '@apollo/client';
import { GET_COURSES } from '../../graphql/Query'
import { DELETE_COURSE } from '../../graphql/Mutation'
import { useMutation } from '@apollo/client';

const AllCourses = () => {
  let count = 1
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  //=====================

  const [deleteCourse] = useMutation(DELETE_COURSE, {
    refetchQueries: [
      GET_COURSES
    ]
  })

  const { data, loading } = useQuery(GET_COURSES, {
    pollInterval: 300
  })
  console.log(data);
  const handleViewDetails = (course) => {
    setSelectedCourse(course);
    setShowDetailsModal(true);
  };

  const handleEditCourse = (course) => {
    setSelectedCourse(course);
    setShowEditModal(true);
  };

  const handleDeleteCourse = (course) => {
    setSelectedCourse(course);
    setShowDeleteModal(true);


  };

  const handleDeleteConfirm = () => {
    // delete the course with the selectedCourse.id
    deleteCourse({
      variables: {
        "courseId": `${selectedCourse.id}`
      }
    }).then(() => {
      setShowDeleteModal(false);
    })
  };



  return (
    <>
      <h1 style={{ textAlign: 'center' }}>ALL COURSES</h1>

      {
        loading ?
          <Spinner animation="border" role="status" className="mx-auto d-block">
            <span className="visually-hidden">Loading...</span>
          </Spinner> :
          <Table bordered style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Course Name</th>
                <th>Tag</th>
                <th>View Details</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data && data.getAllCourse.map((course, index) => (
                <tr key={course.id}>
                  <td>{index + 1}</td>
                  <td>{course.courseName}</td>
                  <td>{course.tag}</td>
                  <td>
                    <Button variant="info" size="sm" style={{ fontSize: 12 }} onClick={() => handleViewDetails(course)}>
                      View
                    </Button>
                  </td>
                  <td>
                    <Button variant="warning" size="sm" style={{ fontSize: 12 }} onClick={() => handleEditCourse(course)}>
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button variant="danger" size="sm" style={{ fontSize: 12 }} onClick={() => handleDeleteCourse(course)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
      }


      {

        showEditModal ? <EditCourse course={selectedCourse} setShowEditModal={setShowEditModal} showEditModal={showEditModal} />
          : ""
      }

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the course {selectedCourse && selectedCourse.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Details Modal */}
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg">
        <Modal.Header
          closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 12 }}>
          {
            selectedCourse && (
              <>
                <p>
                  <strong>Name:</strong> {selectedCourse.courseName}
                </p>
                <p>
                  <strong>Tag:</strong> {selectedCourse.tag}
                </p>
                <p>
                  <strong>Description:</strong> {selectedCourse.description}
                </p>
                <p>
                  <strong>Points:
                    {
                      selectedCourse.points.map(pdata =>
                        <p style={{ fontWeight: 'normal' }}>{count++}. {pdata}</p>
                      )
                    }
                  </strong>
                </p>
                <p>
                  <strong>Created At:</strong> {selectedCourse.createdDateTime}
                </p>
              </>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllCourses;