/* eslint-disable react/jsx-no-target-blank */

import React, { useState } from 'react'
import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import Aboutgif from '../Images/about.us mp4.mp4'
import brochure from '../Images/Download Brochure.gif'
import Democlass from '../Images/Schedule a demo class.gif'
import bookCareer from '../Images/Book Free Career Counselling.gif'
import '../ComponentsCss/About.css'
import { Link } from 'react-router-dom'
export default function About() {
    const [state, setState] = useState(false)

    return (
        <>
            <Container fluid id="aboutId" className="aboutUsContainer">
                <Row>
                    <Col md={5}>
                        <video style={{ width: '100%', height: 460 }} autoPlay loop muted id='video' fluid>
                            <source src={Aboutgif} type='video/mp4' fluid />
                        </video>
                    </Col>
                    <Col md={7} className='aboutUsIntro'>
                        <p className='introductionP m-auto' style={{marginTop:20}}>INTRODUCTION</p>
                        <h2 className='introductionWelcome'>
                            Welcome to the ScreenWork Institute of Animation and Multimedia
                        </h2>
                        <h2 style={{ backgroundColor: '#ffb606 ', height: 2, width: '100%' }}> </h2>
                        <div>
                            <p className='introductionText'>
                                Screenwork Institute of Animation and Multimedia
                                Create Your Bright Future with us, Learn the High-Quality Education in Multimedia.
                                <br />
                                Screenwork Institute of Animation and Multimedia is an organization that has a line of premiere
                                training institutes across India along with state-of-the-art production facilities under the brand
                                name Screenwork Institute. Screenwork Institute of Animation and Multimedia provides 100%
                                job Placement support.
                                <br /></p>
                            {
                                state === false ?
                                    <>
                                        <Link style={{ borderBottom: '1px solid #0984e3' }} onClick={() => setState(true)}>View More</Link>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                        {
                            state === true ?
                                <>
                                    <div>
                                        <p className='introductionTextMore'>
                                            We are committed to creating successful professionals in the field of Animation, Visual Effects
                                            (VFX), Graphics, Motion Graphics & Web Design. We provide a launch pad for aspiring artists
                                            and creative minds by nurturing their talent and giving them industry exposure.
                                            <br />
                                            Screenwork programs are united by one single focus – to make you the best creatively for an
                                            exciting career in your chosen profession. Get a chance to learn industry-grade level animation
                                            and other multimedia courses.
                                            <br />
                                            If you are wondering which program is the right fit for you, sign up for our free career
                                            counseling session. Choose a program and get started on the path to your bright and successful
                                            career!
                                            <br />
                                            Our mission is to fulfill the gap between the needs of the rapidly booming animation, gaming,
                                            and visual special effects industry for skilled professionals who fulfill the industry's needs.
                                            To ensure the requisite skills and abilities Screenwork students are taught on extremely high-
                                            end computer platforms that can handle the demands made on technology by animation and
                                            gaming disciplines.
                                        </p>
                                        {
                                            state === true ?
                                                <>
                                                    <Link style={{ borderBottom: '1px solid #0984e3' }} onClick={() => setState(false)}>View Less</Link>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </Col>
                    <Col md={12} style={{ marginTop: 40 }}>
                        <Row style={{ backgroundColor: 'rgba(20, 23, 28, 0.1)', height: 2, marginBottom: 30 }}></Row>
                    </Col>
                </Row>
            </Container>
            <Container className="aboutUsIconContainer">
                <Row>
                    <Col md={4}>
                        <a href='https://wa.me/+918889991876' target='_blank'>
                            <Card className='FeaturesCard'>
                                <Card.Body>
                                    <Row>
                                        <Image src={bookCareer} style={{ width: 330, height: 200 }} className="img-fluid mx-auto d-block FeaturesCardImg" />
                                    </Row>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href='https://wa.me/+918889991876' target='_blank'>
                            <Card className='FeaturesCard'>
                                <Card.Body>
                                    <Row>
                                        <Image src={brochure} style={{ width: 330, height: 200 }} className="img-fluid mx-auto d-block FeaturesCardImg" />
                                    </Row>

                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href='https://wa.me/+918889991876' target='_blank'>
                            <Card className='FeaturesCard'>
                                <Card.Body>
                                    <Row>
                                        <Image src={Democlass} style={{ width: 330, height: 200 }} className="img-fluid mx-auto d-block FeaturesCardImg" />
                                    </Row>

                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
