import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import video from '../Images/20220923-130717-538.MP4'
import imgs1 from '../Images/IMG_7960.jpg'
import imgs2 from '../Images/IMG_7970.jpg'
import imgs3 from '../Images/IMG_7976.jpg'
import imgs4 from '../Images/IMG_7988.jpg'
import imgs5 from '../Images/IMG_7991.jpg'
import imgs6 from '../Images/IMG_7994.jpg'
import '../ComponentsCss/Gallery.css'

export default function Gallery() {

    const images = [
        {
            src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        },
        {
            src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        },
        {
            src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        },
    ];


    return (
        <Container>
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: '#002147', fontWeight: 'bold', fontFamily: 'DM Sans' }} >
                        GALLERY
                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col md={3} className="mx-auto d-block">
                    <div className='d-none d-lg-block mx-auto d-block' style={{ width: '290px', height: '100px' }}>
                        <video src={video} controls width="100%" style={{ marginTop: 10, borderRadius: 10 }} >
                        </video>
                    </div>
                </Col>
                <Col md={8} className="mx-auto d-block">
                    <Row>
                        <Col md={4}>
                            <Image src={imgs1} style={{ width: '100%', marginTop: 10, borderRadius: 10 }} />
                        </Col>
                        <Col md={4}>
                            <Image src={imgs2} style={{ width: '100%', marginTop: 10, borderRadius: 10 }} />
                        </Col>
                        <Col md={4}>
                            <Image src={imgs3} style={{ width: '100%', marginTop: 10, borderRadius: 10 }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className='galleryPicCol'>
                            <Image src={imgs4} className='galleryPic' />
                        </Col>
                        <Col md={4} className='galleryPicCol'>
                            <Image src={imgs5} className='galleryPic' />
                        </Col>
                        <Col md={3} className='galleryPicCol'>
                            <Image src={imgs6} className='galleryPic' />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
