
import React from 'react'
import { Container, Row, Col, Card, Image, Spinner } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../ComponentsCss/PLacement.css'
import cardpic from '../Images/f3.jpg'
import ankita from '../Images/gpic2.jpeg'
import Devanshi from '../Images/Devanshi.jpeg'
import anshul from '../Images/pic5.png'
import { useQuery } from '@apollo/client';
import { GET_TESTIMONIAL } from '../graphql/Query';



export default function SlickSliderPlacement() {
    const settings = {

        className: "center",
        infinite: true,
        slidesToShow: 2,
        swipeToSlide: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 3000,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const { data, loading } = useQuery(GET_TESTIMONIAL)

    console.log("data", data)


    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Container fluid style={{ marginTop: '30px', marginBottom: 50 }}>
                            {
                                loading ?
                                    <div className='mx-auto d-block'>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden" style={{ color: "#fff" }}>Loading...</span>
                                        </Spinner>
                                    </div>
                                    :
                                    <>
                                        <Row style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px' }}>
                                            <Slider {...settings}>
                                                {
                                                    data && data.getTestimonial.map(list => {

                                                        return (
                                                            <div>
                                                                <Card className='SLiderCard mx-auto d-block' style={{ backgroundColor: 'transparent', borderColor: 'rgba(255, 255, 255, 0.2)', width: '95%', borderWidth: '0px' }} >
                                                                    <Card.Body>
                                                                        <Row>
                                                                            <Col md={5}>
                                                                                <Image src={`https://screenwork-bucket.s3.ap-south-1.amazonaws.com/${list.profileImage}`} className='img-fluid mx-auto d-block' style={{ width: "200px", height: "200px", borderRadius: 30 }} />
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                <p className='textonCard' style={{ color: '#fff', fontWeight: 550, fontSize: 15 }}>
                                                                                    {list.discription}
                                                                                </p>
                                                                                <Row>
                                                                                    <p className='textonCard' style={{ color: '#fff', fontWeight: 750, fontSize: 20 }}>
                                                                                        {list.name} </p>
                                                                                    <p className='textonCard' style={{ color: '#ffb606', fontWeight: 750, fontSize: 15, marginTop: -15 }}>
                                                                                        {list.designation}<br /> ({list.companyname})  </p>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>

                                                        )

                                                    })

                                                }
                                            </Slider>
                                        </Row>
                                    </>

                            }

                            {/* <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', borderColor: 'rgba(255, 255, 255, 0.2)', width: '95%', borderWidth: '0px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={Devanshi} className='img-fluid mx-auto d-block cardImg' />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#fff', fontWeight: 550, fontSize: 15 }}>
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.

                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: '#fff', fontWeight: 750, fontSize: 20 }}>
                                                            Devyanshi Patel  </p>
                                                            <p className='textonCard' style={{ color: '#ffb606', fontWeight: 750, fontSize: 18, marginTop: -15 }}>
                                                            Interior designer<br/> (Archive design studio )  </p>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                    </div>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', borderColor: 'rgba(255, 255, 255, 0.2)', width: '95%', borderWidth: '0px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={anshul} className='img-fluid mx-auto d-block cardImg' />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#fff', fontWeight: 550, fontSize: 15 }}>
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.

                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: '#fff', fontWeight: 750, fontSize: 20 }}>
                                                            Anshul Pandey  </p>
                                                            <p className='textonCard' style={{ color: '#ffb606', fontWeight: 750, fontSize: 18, marginTop: -15 }}>
                                                            Marketing Executive </p>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className='SLiderCard' style={{ backgroundColor: 'transparent', borderColor: 'rgba(255, 255, 255, 0.2)', width: '95%', borderWidth: '0px' }} >
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5}>
                                                        <Image src={cardpic} className='img-fluid mx-auto d-block cardImg' />
                                                    </Col>
                                                    <Col md={7}>
                                                        <p className='textonCard' style={{ color: '#fff', fontWeight: 550, fontSize: 15 }}>
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.
                                                            Able an hope of body. Any nay shyness article matters own removal nothing his forming.

                                                        </p>
                                                        <Row>
                                                            <p className='textonCard' style={{ color: '#fff', fontWeight: 750, fontSize: 20 }}>
                                                                Vipin Rai  </p>
                                                            <p className='textonCard' style={{ color: '#ffb606', fontWeight: 750, fontSize: 18, marginTop: -15 }}>
                                                                Development Student  </p>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
 */}



                        </Container>
                    </Col>
                </Row>

            </Container>

        </div>
    )
}
