import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';

const RefundPolicy = () => {
    return (
        <>
            <Navigation />

            <div className="container mt-5">
                <h2 className="mb-4 text-center">Refund Policy</h2>
                <p className='mt-5 fs-5 ' >
                    Returns and Refunds Policy. Thank you for shopping at online.screenwork.in
                </p>
                <p className='mt-5 fs-5 '>
                    Non-tangible irrevocable goods ("Digital products")
                </p>
                <p className='mt-5 fs-5 '>
                    We do not issue refunds for non-tangible irrevocable goods ("digital products") once the order is confirmed and the product is sent.
                </p>
                <p className='mt-5 fs-5 '>
                    We recommend contacting us for assistance if you experience any issues receiving or downloading our products.
                </p>
                <h5 className='mt-5 fs-5 '>Contact us for any issues:</h5>
                <ul className='mt-5 fs-5 '>
                    <li>By email: info@screenwork.in</li>
                </ul>
            </div>
            <Footer />
        </>
    );
}

export default RefundPolicy;
