/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  Row,
  Col,
  Image,
  Button,
  NavDropdown,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import logo2 from "../Images/Use this on websitelogo.png";
import "../ComponentsCss/Navigation.css";
import { FaPhone, FaEnvelopeOpen, FaCheckDouble } from "react-icons/fa";
import { Link as Link1 } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_CHECK_FEES, MUTATION_ENROLL } from "../graphql/Mutation";
import {
  GET_CHECK_FEES,
  GET_ENROLL,
  GET_NONE_OF_THESE,
} from "../graphql/Query";
import swal from "sweetalert";
import { Link } from "react-scroll";
import location from "../Images/icons/loca.png";

export default function Navigation() {
  const [stickyClass, setStickyClass] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const { data: noneData } = useQuery(GET_NONE_OF_THESE);

  useEffect(() => { });
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validateFees, setValidateFees] = useState(false);
  const [feesName, setFeesName] = useState("");
  const [number, setNumber] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseData, setCourseData] = useState();

  const sethandler = () => {
    if (scrollPosition > 50) {
      setStickyClass("top");
    } else {
      setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    sethandler();
  }, [scrollPosition]);

  const token = localStorage.getItem("adminToken");

  function logoutHandle() {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminId");
  }

  const [createCheckFees] = useMutation(MUTATION_CHECK_FEES, {
    refetchQueries: [GET_CHECK_FEES],
  });
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  function checkFees(data, name) {
    setCourseData(data);
    setCourseName(name);
    handleShow2();
  }

  function handleSubmitCheckFees(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidateFees(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidateFees(false);
      event.preventDefault();
      createCheckFees({
        variables: {
          checkFeesInput: {
            name: `${feesName}`,
            number: `${number}`,
            courseName: `${courseName}`,
          },
        },
      });
      swal({
        title: "Thank you for enquiring",
        text: "we will get back to you soon",
        icon: "success",
      });
      setFeesName("");
      setNumber("");
      setCourseName("");
      handleClose1();
      handleClose2();
    }
  }
  const [validatedEdit, setValidatedEdit] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [qualification, setQualification] = useState("");
  const [course, setCourse] = useState("");

  const [createEnroll] = useMutation(MUTATION_ENROLL, {
    refetchQueries: [GET_ENROLL],
  });

  function handleSubmitEnroll(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidatedEdit(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidatedEdit(false);
      event.preventDefault();
      createEnroll({
        variables: {
          enrollInput: {
            name: `${name}`,
            contact: `${contact}`,
            qualification: `${qualification}`,
            course: `${courseName}`,
          },
        },
      });
      swal({
        title: "Thank you for enquiring ScreenWork Multimedia,",
        text: "we will get back to you soon",
        icon: "success",
      });
      setName("");
      setContact("");
      setQualification("");
      setCourse("");
      handleClose();
      handleClose2();
    }
  }

  const [scrollY, setScrollY] = useState(0);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll2 = () => {
    const currentScrollPos = window.scrollY;
    console.log("currentScrollPos", currentScrollPos);
    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll2);

    return () => window.removeEventListener("scroll", handleScroll2);
  });

  console.log("visi", visible);
  console.log("prevScrollPos", prevScrollPos);

  return (
    <div>
      <Container className="topHeader d-none d-md-block" fluid>
        <Container>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Row style={{ marginTop: 10 }}>
                    <Col md={1}>
                      <img src={location} height={45} />
                    </Col>
                    <Col md={11}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          fontWeight: "bold",
                          fontFamily: "DM sans",
                          marginLeft: "25px",
                        }}
                      >
                        ADDRESS
                      </p>
                      <p
                        style={{
                          fontSize: 12,
                          color: "#fff",
                          fontFamily: "DM sans",
                          marginTop: "-15px",
                          marginLeft: "25px",
                          lineHeight: 1,
                        }}
                      >
                        Behind Hero Showroom Near Tanishq, Banda Road, Makronia,
                        Sagar (M.P.) 470004
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}>
                  <div className="vLine"></div>
                </Col>
                <Col md={3}>
                  <Row style={{ marginTop: 10 }}>
                    <Col md={1}>
                      <FaEnvelopeOpen
                        style={{ color: "#fff", fontSize: "40px" }}
                      />
                    </Col>
                    <Col md={10}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          fontWeight: "bold",
                          fontFamily: "DM sans",
                          marginLeft: "25px",
                          marginTop: "9px",
                        }}
                      >
                        EMAIL
                      </p>
                      <p
                        style={{
                          fontSize: 12,
                          color: "#fff",
                          fontFamily: "DM sans",
                          marginTop: "-15px",
                          marginLeft: "25px",
                          lineHeight: 1,
                        }}
                      >
                        screenworkmultimedia@gmail.com
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}>
                  <div className="vLine"></div>
                </Col>

                <Col md={3}>
                  <Row style={{ marginTop: 10 }}>
                    <Col md={1}>
                      <FaPhone style={{ color: "#fff", fontSize: "40px" }} />
                    </Col>
                    <Col md={10}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          fontWeight: "bold",
                          fontFamily: "DM sans",
                          marginLeft: "25px",
                          marginTop: "9px",
                        }}
                      >
                        CONTACT
                      </p>
                      <p
                        style={{
                          fontSize: 12,
                          color: "#fff",
                          fontFamily: "DM sans",
                          marginTop: "-15px",
                          marginLeft: "25px",
                          lineHeight: 1,
                        }}
                      >
                        +91 - 888 9991 876
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </Container>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        bg="white"
        fixed={scrollY === 0 ? "" : "top"}
      >
        <Container>
          <div style={{ width: "240px", height: "65px" }}>
            <Image className="imglogoscreen" src={logo2} />
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            className="justify-content-end"
          >
            <Nav className="me-auto">
              <Link1 to="/" className="nav-link headerLink">
                HOME
              </Link1>
              <Link to="aboutId" spy={true} offset={-70} duration={500} style={{ cursor: "pointer" }} className="nav-link headerLink">
                ABOUT US
              </Link>
              <NavDropdown
                title="OFFLINE COURSES"
                id="basic-nav-dropdown"
                className="headerLink2"
              >
                <div className="navbarDiv">
                  <div style={{ overflowY: "scroll", height: "500px" }}>
                    {noneData &&
                      noneData.getNoneofThese.map((cData) => (
                        <p
                          onClick={() => checkFees(cData, cData.courseName)}
                          className="drop-down-link"
                          style={{ padding: 10 }}
                        >
                          {cData.courseName}
                        </p>
                      ))}
                  </div>
                </div>
              </NavDropdown>
              <a href="https://online.screenwork.in/" className="nav-link headerLink" target="_blank">
                ONLINE COURSES
              </a>
              <a href="http://www.overallcreatives.com/" className="nav-link headerLink" target="_blank">
                SERVICES
              </a>
              <Link
                to="testId"
                spy={true}
                offset={-70}
                duration={500}
                style={{ cursor: "pointer" }}
                className="nav-link headerLink"
              >
                TESTIMONIALS
              </Link>
              <Link1
                to="/contactUs"
                spy={true}
                offset={-70}
                duration={500}
                style={{ cursor: "pointer" }}
                className="nav-link headerLink"
              >
                CONTACT US
              </Link1>
              {token ? (
                <>

                  <NavDropdown
                    title="Admin Options"
                    id="basic-nav-dropdown"
                    className="headerLink2"
                  >
                    <NavDropdown.Item href="#action3">
                      <Link1
                        to="/admindashboard/testimonials"
                        className="nav-link headerLink"
                        style={{fontSize: "11px"}}
                      >
                        DASHBOARD
                      </Link1>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                    <Link1
                    to="#action2"
                    className="nav-link headerLink"
                    onClick={() => logoutHandle()}
                    style={{fontSize: "11px"}}
                  >
                    LOGOUT
                  </Link1>
                    </NavDropdown.Item>
                  </NavDropdown>
              
                </>
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show2} size="lg" onHide={handleClose2}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card style={{ borderWidth: "0px" }}>
            <Card.Body>
              <h2
                style={{
                  color: "#002147",
                  fontWeight: "bold",
                  fontFamily: "DM Sans",
                }}
              >
                {courseData && courseData.courseName}
              </h2>
              <p
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 20,
                  color: " #ffb606",
                  fontFamily: "DM Sans",
                }}
              >
                About this Course
              </p>
              <p
                style={{
                  color: " #666666",
                  fontWeight: "bold",
                  fontFamily: "DM Sans",
                  fontSize: "13px",
                }}
              >
                {courseData && courseData.description}
              </p>
              <p
                style={{
                  marginTop: 20,
                  fontWeight: "bold",
                  fontSize: 15,
                  color: "#002147",
                  fontFamily: "DM Sans",
                }}
              >
                LEARNING OUTCOMES
              </p>
              {courseData &&
                courseData.points.map((pData) => (
                  <p
                    style={{
                      marginTop: 10,
                      fontWeight: 400,
                      fontSize: 13,
                      color: " #666666",
                      fontFamily: "DM Sans",
                    }}
                  >
                    <FaCheckDouble style={{ color: "#ffb606" }} /> {pData}
                  </p>
                ))}
            </Card.Body>
          </Card>
          <Col>
            <Button
              style={{
                maxWidth: "50%",
                minWidth: "37%",
                height: 50,
                color: "black",
                fontWeight: "bold",
                marginTop: 10,
                fontFamily: "DM Sans",
              }}
              variant="warning"
              onClick={handleShow}
            >
              Enroll Now
            </Button>
            <Button
              style={{
                maxWidth: "50%",
                minWidth: "37%",
                height: 50,
                color: "black",
                fontWeight: "bold",
                marginTop: 10,
                fontFamily: "DM Sans",
                backgroundColor: "#3498db",
                marginLeft: 10,
              }}
              onClick={handleShow1}
            >
              Check Fees
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedEdit}
            onSubmit={handleSubmitEnroll}
          >
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="6"
                controlId="validationCustom01"
                style={{ marginTop: "10px" }}
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                controlId="validationCustom02"
                style={{ marginTop: "10px" }}
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Phone No."
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom02"
                style={{ marginTop: "15px" }}
              >
                <Form.Label>Qualification</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder="Enter Your Qualification"
                  onChange={(e) => setQualification(e.target.value)}
                  value={qualification}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom02"
                style={{ marginTop: "15px" }}
              >
                <Form.Label>Course</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Course"
                  value={courseName}
                  disabled
                />
              </Form.Group>
            </Row>
            <Button
              className="mx-auto d-block"
              type="submit"
              variant="warning"
              style={{
                width: "37%",
                height: 40,
                color: "black",
                fontWeight: "bold",
                marginTop: 10,
                fontFamily: "DM Sans",
              }}
            >
              Apply Now
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validateFees}
            onSubmit={handleSubmitCheckFees}
          >
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="6"
                controlId="validationCustom01"
                style={{ marginTop: "10px" }}
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => setFeesName(e.target.value)}
                  value={feesName}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                controlId="validationCustom02"
                style={{ marginTop: "10px" }}
              >
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Mobile No."
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom02"
                style={{ marginTop: "15px" }}
              >
                <Form.Label>Course Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={courseName}
                  value={courseName}
                  disabled
                />
              </Form.Group>
            </Row>
            <Button
              className="mx-auto d-block"
              type="submit"
              variant="warning"
              style={{
                width: "37%",
                height: 40,
                color: "black",
                fontWeight: "bold",
                marginTop: 10,
                fontFamily: "DM Sans",
              }}
            >
              Check Fees
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
