import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Container, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { MUTATION_COURSE } from "../../graphql/Mutation";
import { GET_COURSES } from "../../graphql/Query";

const NewCourse = () => {
  const [points, setPoints] = useState([]);
  const [tags, setTags] = useState("None of these");
  const [courseName, setCourseName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');

  const [createCourse] = useMutation(MUTATION_COURSE, {
    refetchQueries: [GET_COURSES],
  });

  function handlePointsChange(index, value) {
    const newPoints = [...points];
    newPoints[index] = value;
    setPoints(newPoints);
  }

  function handleAddPoint() {
    setPoints([...points, ""]);
  }

  function handleRemovePoint(index) {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  }

  function handleTagsChange(event) {
    const selectedTags = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setTags(selectedTags);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const currentDate = new Date();
    console.log(tags);
    createCourse({
      variables: {
        courseInput: {
          courseName: `${courseName}`,
          description: `${courseDescription}`,
          points: points,
          tag: `${tags}`,
          createdDateTime: `${currentDate.toDateString()}`,
          status: "active",
        },
      },
    }).then((data) => {
      console.log(data);
      alert("Course Added Successfully");
      setCourseName("");
      setCourseDescription("");
      setTags([]);
      setPoints([]);
    });
  }

  return (
    <>
      <Container fluid>
        <h3 style={{ textAlign: "center", fontWeight: 'bold' }}>ADD NEW COURSES</h3>
        <hr color="black"></hr>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="courseName">Course Name</label>
            <input type="text" className="form-control" id="courseName" value={courseName} onChange={(event) => setCourseName(event.target.value)} />
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <label htmlFor="courseDescription">Course Description</label>
            <textarea className="form-control" id="courseDescription" rows="3" value={courseDescription} onChange={(event) => setCourseDescription(event.target.value)}></textarea>
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <label htmlFor="points">Points</label>
            {
              points.map((point, index) => (
                <div className="input-group mb-3" key={index}>
                  <textarea type="text" className="form-control" value={point} onChange={(event) => handlePointsChange(index, event.target.value)} />
                  <div className="input-group-append">
                    <button type="button" className="btn btn-outline-danger" onClick={() => handleRemovePoint(index)} disabled={points.length === 1}>
                      <FaMinus />
                    </button>
                  </div>
                </div>
              ))}
            <button type="button" className="btn btn-outline-primary mb-3" onClick={handleAddPoint}>
              <FaPlus /> Add Point
            </button>
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <label htmlFor="tags">Tags</label>
            <select className="form-control" id="tags" value={tags} onChange={handleTagsChange}>
              <option value="Boring Degree Courses">No More Boring Degree Courses</option>
              <option value="Popular Courses">Popular Courses</option>
              <option value="All Top Courses">All Top Courses</option>
              <option value="None of these">None of these</option>
            </select>
          </div>
          {
            courseName === '' || courseDescription === '' ?
              <Button type="submit" className="mx-auto d-block btn btn-primary" disabled style={{ marginTop: 20, width: 100 }}>Add</Button>
              :
              <Button type="submit" className="mx-auto d-block btn btn-primary" style={{ marginTop: 20, width: 100 }}>Add</Button>
          }
        </form>
      </Container>
    </>
  );
};

export default NewCourse;
