import React from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import '../ComponentsCss/PLacement.css'
import cardpic from '../Images/f3.jpg'
import SlickSliderPlacement from './SlickSliderPlacement'
export default function PlacementSection() {
    return (
        <Container fluid style={{ backgroundColor: '#002147' }} id="testId">
            <Row>
                <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                    <h2 style={{ color: '#fff', fontWeight: 'bold' }} >
                        Testimonials
                    </h2>
                    <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '15%', marginTop: 20 }}> </h2>
                </Col>
            </Row>
            <SlickSliderPlacement />
        </Container>
    )
}
