import React from 'react'
import { Container, Spinner, Table } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import { GET_ENROLL } from '../../graphql/Query'
import Moment from 'react-moment';

export default function FeesRequest() {

    const { data, loading } = useQuery(GET_ENROLL, {
        pollInterval: 300
    })

    return (
        <>
            <Container fluid>
                <h3 style={{ textAlign: 'center' }}>Fees Request Details</h3>
                <hr color='black'></hr>
                <Table bordered >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                            <th>Course</th>
                            <th>Date & Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                data && data.getEnroll.slice().reverse().map(userData =>
                                    <tr>
                                        <td>{userData.name}</td>
                                        <td>{userData.contact}</td>
                                        <td>{userData.course}</td>
                                        <td> <Moment format="DD/MM/YYYY hh:mm a">{userData.createdDateTime}</Moment></td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    )
}
