import React from 'react'
import Navigation from '../Components/Navigation'
import Header from '../Components/Header'
import About from '../Components/About'
import WhychooseUs from '../Components/WhychooseUs'
import NoMoreBoringCourses from '../Components/NoMoreBoringCourses'
import PopularCourses from '../Components/PopularCourses'
import TopCategories from '../Components/TopCategories'
import StudentEnquiry from '../Components/StudentEnquiry'
import OurTeam from '../Components/OurTeam'
import JobAssistance from '../Components/JobAssistance'
import TopEvents from '../Components/TopEvents'
import OurAffliations from '../Components/OurAffliations'
import PlacementSection from '../Components/PlacementSection'
import Gallery from '../Components/Gallery'
import FooterJoin from '../Components/FooterJoin'
import Footer from '../Components/Footer'
import ContactUs from '../Components/ContactUs'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo2 from '../Images/logoscreen.png'
import AffiationPartnership from './AffiationPartnership'
import LocationMap from './LocationMap'

export default function Home() {
    return (
        <>
            <Navigation />
            <Header />
            <About />
            <WhychooseUs />
            <NoMoreBoringCourses />
            <PopularCourses />
            <TopCategories />
            <StudentEnquiry />
            <OurTeam />
            {/* <JobAssistance /> */}
            <TopEvents />
            <OurAffliations />
            <PlacementSection />
            {/* <ContactUs /> */}
            <Gallery />
            <AffiationPartnership />
            {/* <FooterJoin /> */}
            <LocationMap />
            <Footer />
            <FloatingWhatsApp
                phoneNumber="+918889991876"
                accountName="ScreenWork"
                avatar={logo2}
                allowEsc
                allowClickAway
            />

        </>
    )
}
