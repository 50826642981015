import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../ComponentsCss/Footer.css'

export default function Footer() {

    return (
        <Container fluid style={{ backgroundColor: '#182143' }}>
            <Row>
                <Container style={{ marginTop: 50 }}>
                    <Row>
                        <Col>
                            <div className='d-flex justify-content-center '>
                                <div style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center' }} className='ml-3 ' >
                                    <a href="/terms-of-use" style={{ textDecoration: 'none', color: '#fff' }}>Terms of Use</a>
                                </div>

                                <div style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center', marginLeft: "15px" }} className='ml-3'>
                                    <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#fff' }}>Privacy Policy</a>
                                </div>
                                <div style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center', marginLeft: "15px" }} className='ml-3'>
                                    <a href="/about-us" style={{ textDecoration: 'none', color: '#fff' }}>About Us</a>
                                </div>
                                {/* <div style={{ color: '#fff', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center', marginLeft: "15px" }} className='ml-3'>
                                    <a href="/faq" style={{ textDecoration: 'none', color: '#fff' }}>FAQs</a>
                                </div> */}
                                <div style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center', marginLeft: "15px" }} className='ml-3'>
                                    <a href="/contactUs" style={{ textDecoration: 'none', color: '#fff' }}>Contact Us</a>
                                </div>
                                <div style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center', marginLeft: "15px" }} className='ml-3'>
                                    <a href="/refund-policy" style={{ textDecoration: 'none', color: '#fff' }}>Refund Policy</a>
                                </div>
                            </div>
                            <h2 style={{ color: '#fff', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center' }} className='mt-3'>
                                ScreenWork Animation & Multimedia, Makroniya Sagar
                            </h2>
                            <div>

                            </div>
                            <p style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center' }}>
                                We Empower Education through Technology.
                            </p>
                            <p style={{ color: '#fff', fontFamily: 'Poppins', textAlign: 'center', fontSize: 12 }}>
                                Developed & Maintain by <a href="https://softseekersinfotech.com/" target="_blank" style={{ textDecoration: 'none', color: '#fff' }}>Softseekers Infotech Pvt. Ltd.</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    )
}
