import { Button, Container, Col, Row, Carousel, Modal, Form } from 'react-bootstrap'
import '../ComponentsCss/Header.css'
import Slide from 'react-reveal/Slide';
import { useState } from 'react';
import { MUTATION_ENROLL } from '../graphql/Mutation';
import { useMutation } from '@apollo/client';
import { GET_ENROLL } from '../graphql/Query';
import swal from 'sweetalert';

export default function Header() {

  function editEnroll(ddta) {
    setCourse(ddta)
    handleShow()
  }

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validatedEdit, setValidatedEdit] = useState(false)
  const [name, setName] = useState("")
  const [contact, setContact] = useState("")
  const [qualification, setQualification] = useState("")
  const [course, setCourse] = useState("")


  const [createEnroll] = useMutation(MUTATION_ENROLL, {
    refetchQueries: [
      GET_ENROLL
    ]
  })

  function handleSubmitEnroll(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidatedEdit(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidatedEdit(false);
      event.preventDefault();
      createEnroll({
        variables: {
          "enrollInput": {
            "name": `${name}`,
            "contact": `${contact}`,
            "qualification": `${qualification}`,
            "course": `${course}`
          }
        }
      })
      swal({
        title: "Thank you for enquiring ScreenWork Multimedia,",
        text: "we will get back to you soon",
        icon: "success",

      })
      setName('')
      setContact('')
      setQualification('')
      setCourse('')
      handleClose()
    }
  }
  return (
    <>
      <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: 10 }}>
                        <h5 style={{ color: '#fff', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN 3D ANIMATION & VFX
                        </h5>
                        <Button onClick={() => editEnroll('LEARN 3D ANIMATION & VFX')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#bdc3c7', borderColor: '#bdc3c7', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>
                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>
                {/* <Row>
                  <Col sm={7}>
                    <Slide left>
                    <div  style={{width:"60%",height:"70%",background:"#fff",backgroundColor:"rgba(235, 235, 235, 0.5)"}}>
                      <p style={{ color: '#fff', fontWeight: 'bold', marginTop: '-50px', fontSize: 45, textAlign: 'left', lineHeight: 1.2 }} id="hero_text">
                        LEARN 3D ANIMATION & VFX
                      </p>
                      </div>
                    </Slide>
                    <Row style={{ marginTop: 25 }}>
                      <Col md={5} style={{ textAlign: 'left', marginTop: 10 }}>
                        <Slide left>
                          <Button onClick={() => editEnroll('LEARN 3D ANIMATION & VFX')} style={{ width: '100%', height: 50, fontWeight: 'bold', background: '#bdc3c7', borderColor: '#bdc3c7', color: '#fff' }}>ADMISSION OPEN</Button>{' '}
                        </Slide>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
              </Container>
            </div>
          </header>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro2'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10 }}>

                        <h5 style={{ color: '#eb6025', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          UG|PG|DIPLOMA IN ANIMATION
                        </h5>
                        <Button onClick={() => editEnroll('UG|PG|DIPLOMA IN ANIMATION')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#ed8852', borderColor: '#ed8852', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>

                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </Container>
            </div>
          </header>
        </Carousel.Item>



        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro4'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10 }}>

                        <h5 style={{ color: '#34495e', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN VFX
                        </h5>
                        <Button onClick={() => editEnroll('LEARN VFX')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#2b447a', borderColor: '#2b447a', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>{' '}

                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>

              </Container>
            </div>
          </header>
        </Carousel.Item>

        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro5'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: 10 }}>
                        <h5 style={{ color: '#ecf0f1', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN GRAPHIC<br /> DESIGN
                        </h5>
                        <Button onClick={() => editEnroll('LEARN GRAPHIC DESIGN')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#ebec86', borderColor: '#ebec86', color: '#34495e', marginBottom: 10 }}>ADMISSION OPEN</Button>{' '}
                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>

              </Container>
            </div>
          </header>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro6'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10 }}>

                        <h5 style={{ color: '#833d09', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN UI|UX|WEB
                        </h5>
                        <Button onClick={() => editEnroll('LEARN UI|UX|WEB')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#835aad', borderColor: '#835aad', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>{' '}

                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>

              </Container>
            </div>
          </header>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <header id='header'>
            <div className='intro7'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10 }}>

                        <h5 style={{ color: '#130f40', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN INTERIOR<br /> DESIGN
                        </h5>
                        <Button onClick={() => editEnroll('LEARN INTERIOR DESIGN')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#9e895e', borderColor: '#9e895e', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>{' '}

                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </Container>
            </div>
          </header>
        </Carousel.Item>
        <Carousel.Item >
          <header id='header'>
            <div className='intro8'>
              <Container className="intro-text">
                <Row>
                  <Col md={6}>
                    <Slide left>
                      <div style={{ width: "100%", height: "100%", background: "#fff", backgroundColor: "rgba(255, 255, 255, 0.5)", padding: 10 }}>

                        <h5 style={{ color: '#6c5ce7', fontWeight: 'bold', fontSize: 45, marginTop: 20 }} id="hero_text">
                          LEARN DIGITAL <br />MARKETING
                        </h5>
                        <Button onClick={() => editEnroll('LEARN DIGITAL MARKETING')} style={{ width: 200, height: 50, fontWeight: 'bold', background: '#0984e3', borderColor: '#0984e3', color: '#fff', marginBottom: 20 }}>ADMISSION OPEN</Button>{' '}

                      </div>
                    </Slide>
                  </Col>
                  <Col md={6}></Col>
                </Row>

              </Container>
            </div>
          </header>
        </Carousel.Item>

      </Carousel>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedEdit} onSubmit={handleSubmitEnroll}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01" style={{ marginTop: '10px' }}>
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" placeholder='Enter Name' onChange={(e) => setName(e.target.value)} value={name} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02" style={{ marginTop: '10px' }}>
                <Form.Label>Phone</Form.Label>
                <Form.Control required type="number" placeholder='Enter Phone No.' onChange={(e) => setContact(e.target.value)} value={contact} />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: '15px' }}>
                <Form.Label>Qualification</Form.Label>
                <Form.Control required as="textarea" placeholder='Enter Your Qualification' onChange={(e) => setQualification(e.target.value)} value={qualification} />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: '15px' }}>
                <Form.Label>Course</Form.Label>
                <Form.Control required type="text" placeholder='Enter Course' value={course} disabled />
              </Form.Group>
            </Row>
            <Button className="mx-auto d-block" type="submit" variant="warning" style={{ width: '37%', height: 40, color: '#fff', fontWeight: 'bold', marginTop: 10, fontFamily: 'DM Sans' }}>Apply Now</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}