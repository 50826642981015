import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, Navigate, Outlet } from "react-router-dom";
import "../../ComponentsCss/Admin/Dashboard.css";
import logo from "../../Images/logoscreen.png";
import {
  FaBars,
  FaHome,
  FaBookmark,
  FaBook,
  FaUserCheck,
  FaPlus,
  FaListAlt,
} from "react-icons/fa";

import { RiContactsLine } from "react-icons/ri";

export default function AdminDashboard() {
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);
  const token = localStorage.getItem("adminToken");

  if (!token) {
    return <Navigate to="/adminlogin" />;
  }

  function handleDown() {
    const listItems = document.querySelectorAll(".sidebar-list li");

    listItems.forEach((item) => {
      item.addEventListener("click", () => {
        let isActive = item.classList.contains("active");

        listItems.forEach((el) => {
          el.classList.remove("active");
        });

        if (isActive) item.classList.remove("active");
        else item.classList.add("active");
      });
    });
  }

  function handleClick() {
    const toggleSidebar = document.querySelector(".toggle-sidebar");
    const logo = document.querySelector(".logo-box");
    const sidebar = document.querySelector(".sidebar");
    toggleSidebar.addEventListener("click", () => {
      sidebar.classList.toggle("close");
    });
    logo.addEventListener("click", () => {
      sidebar.classList.toggle("close");
    });
  }

  return (
    <>
      <div className="sidebar close">
        <Link to="/" className="logo-box">
          <i className="bx bxl-xing">
            <Image src={logo} style={{ height: "70%", width: "50%" }} />
          </i>
          <div className="logo-name">ScreenWork</div>
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/" className="link">
                <i className="bx bx-grid-alt">
                  <FaHome />
                </i>
                <span className="name">Home</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/" className="link submenu-title">
                Home
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/admindashboard/testimonials" className="link">
                <i className="bx bx-line-chart">
                  <FaUserCheck />
                </i>
                <span className="name">Testimonials</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/testimonials"
                className="link submenu-title"
              >
                Testimonials
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/admindashboard/courcerequest" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  <FaBook />
                </i>
                <span className="name">Course Request</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/courcerequest"
                className="link submenu-title"
              >
                Course Request
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/admindashboard/enrollrequest" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  <FaBookmark />
                </i>
                <span className="name">Enroll Request</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/enrollrequest"
                className="link submenu-title"
              >
                Enroll Request
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/admindashboard/newcourse" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  {" "}
                  <FaPlus />{" "}
                </i>
                <span className="name">Add Courses </span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/newcourse"
                className="link submenu-title"
              >
                Add Courses
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/admindashboard/allcourse" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  {" "}
                  <FaListAlt />{" "}
                </i>
                <span className="name">All Courses </span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/allcourse"
                className="link submenu-title"
              >
                All Courses
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/admindashboard/ContactRequest" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  {" "}
                  <RiContactsLine style={{ color: "#fff" }} color="#fff" />{" "}
                </i>
                <span className="name">Contact Request </span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/ContactRequest"
                className="link submenu-title"
              >
                Contact Request
              </Link>
            </div>
          </li>
        </ul>
      </div>

      <section className="home">
        <div className="toggle-sidebar">
          <FaBars
            className="bx bx-menu"
            onClick={() => handleClick()}
            style={{ width: 30, height: 30, marginLeft: 10 }}
          />
        </div>

        <Container style={{ padding: "20px" }} fluid>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
