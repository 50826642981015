import React, { useEffect } from 'react'
import { Container, Row, Col, Image, Button, Card, Form, Spinner, Alert } from 'react-bootstrap'
import '../ComponentsCss/ContactUs.css'
import logo1 from '../Images/faculty-icon-17.jpg'
import Logo2 from '../Images/2nd-removebg-preview.png'
import logo3 from '../Images/3rdlogo.png'
import Navigation from './Navigation'
import { useState } from 'react'
import swal from 'sweetalert';
import { useMutation } from '@apollo/client'
import { MUTATION_CREATE_CONTACTUS } from '../graphql/Mutation'

export default function ContactUs() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [message, setMessage] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [createContactUs, { loading }] = useMutation(MUTATION_CREATE_CONTACTUS)


    function handleSubmit() {
        if (name === "" || email === "" || contact === "" || message === "") {
            swal({
                title: " Input Box is Empty",
                icon: "warning",
                dangerMode: true,
            })
        } else {
            createContactUs({
                variables: {
                    "contactusInput": {
                        "name": `${name}`,
                        "email": `${email}`,
                        "contact": `${contact}`,
                        "message": `${message}`,
                    }
                }
            }).then(() => {
                setName('')
                setEmail('')
                setContact('')
                setMessage('')
                swal({
                    title: "Send successfully, we will contact you as soon as possible.",
                    icon: "success",
                })
            })
        }
    }

    return (
        <>
            <Navigation />
            <Container >
                <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: 50 }}>
                        <h2 style={{ color: '#002147', fontWeight: 'bold' }} >CONTACT US</h2>
                        <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '20%', marginTop: 20 }}></h2>
                        <Container>
                            <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 20, letterSpacing: 1, color: '#3498DB' }}>Where Dreams Meet Destination, Ambition Unleashes, and Future Takes Flight</p>
                            <p><span style={{ color: '#3498DB' }}> Join ScreenWork for a Bright Future</span></p>
                        </Container>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col md={4}>
                        <Card id="contactCard" style={{ height: "210px", width: "100%" }}>
                            <Card.Body>
                                <Image src={logo1} className=' img-fluid mx-auto d-block  contactCardImg' />
                                <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#002147', marginTop: -10 }}>CALL US</h4>
                                <p onClick={() => window.location = 'tel:+918889991876"'} style={{ textAlign: 'center', fontSize: 12, cursor: 'pointer' }}>+91-888 9991 876</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card id="contactCard" style={{ height: "210px", width: "100%" }}>
                            <Card.Body>
                                <Image src={Logo2} className=' img-fluid mx-auto d-block  contactCardImg' />
                                <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#002147', marginTop: -10 }}>ADDRESS</h4>
                                <p style={{ textAlign: 'center', fontSize: 12 }}>Behind Hero Showroom Near Tanishq,Banda Road Makronia Sagar(M.P.) 470004</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card id="contactCard" style={{ height: "210px", width: "100%" }}>
                            <Card.Body>
                                <Image src={logo3} className=' img-fluid mx-auto d-block  contactCardImg' />
                                <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#002147', marginTop: -10 }}>EMAIL US</h4>
                                <p onClick={() => window.location = 'mailto:screenworkmultimedia@gmail.com'} style={{ textAlign: 'center', fontSize: 12, cursor: 'pointer' }}>screenworkmultimedia@gmail.com</p>
                                <p onClick={() => window.location = 'mailto:info@screenwork.in'} style={{ textAlign: 'center', fontSize: 12, marginTop: '-15px', cursor: 'pointer' }}>info@screenwork.in</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Col md={12} style={{

                    marginTop: 25
                }}>
                    <Row style={{
                        backgroundColor: 'rgba(20, 23, 28, 0.1)',
                        height: 2,
                        marginBottom: 30
                    }}>

                    </Row>

                </Col>

                <Row>
                    <Col md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.96473447862!2d78.7917474143505!3d23.855385990675867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3978d134e6f8aa0b%3A0x1ee63782c772f8c!2sScreenWork%20Animation%20%26%20Multimedia!5e0!3m2!1sen!2sin!4v1650267710761!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: '100%', padding: 20, height: 500 }}></iframe>
                    </Col>
                    <Col md={6} style={{ marginTop: 35 }}>
                        <Form >
                            <Row>
                                <Col md={12}>

                                    <Form.Control type="text" placeholder="Enter Your Name" onChange={(e) => setName(e.target.value)} value={name} />
                                </Col>

                            </Row>
                            <Row style={{ marginTop: 25 }}>
                                <Col md={12}>

                                    <Form.Control type="text" placeholder="Enter Your Email* " onChange={(e) => setEmail(e.target.value)} value={email} />
                                </Col>

                            </Row>
                            <Row style={{ marginTop: 25 }}>
                                <Col md={12}>

                                    <Form.Control type="text" placeholder="Enter Your Phone Number* " onChange={(e) => setContact(e.target.value)} value={contact} />
                                </Col>

                            </Row>
                            <Row style={{ marginTop: 25 }}>
                                <Col md={12}>

                                    <Form.Control as="textarea" placeholder="Enter Your Message* " rows={7} onChange={(e) => setMessage(e.target.value)} value={message} />
                                </Col>

                            </Row>

                            {
                                loading ?
                                    <Spinner animation="border" role="status" className="mx-auto d-block" style={{ marginTop: 10 }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <Button style={{ width: '40%', height: 50, color: '#fff', fontWeight: 'bold', marginTop: 25 }} className="mx-auto d-block" variant="warning" onClick={() => handleSubmit()}> Send </Button>
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
