import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PartnershipSlider from './PartnershipSlider'

export default function AffiationPartnership() {
    return (
        <>
            <Container fluid style={{ backgroundColor: '#fff' }}>
                <Row>
                    <Container style={{ marginTop: 80, marginBottom: 50 }} fluid>
                        <Row>
                            <Col md={12}>
                                <h2 style={{ color: '#002147', fontWeight: 'bold', textAlign: 'center' }} >
                                    Affiliations & Partnerships
                                </h2>
                                <h2 className='mx-auto d-block' style={{ backgroundColor: '#ffb606 ', height: 2, width: '50%', marginTop: 20 }}> </h2>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <PartnershipSlider />
                            </Col>
                        </Row>

                    </Container>
                </Row>
            </Container>
        </>
    )
}
