import React, { useState } from "react";
import { Container, Row, Col, Button, Card, Modal, Form, Image, } from "react-bootstrap";
import "../ComponentsCss/SIngleCourseDetails.css";
import { useMutation, useQuery } from "@apollo/client";
import { FaCheckDouble, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MUTATION_CHECK_FEES, MUTATION_ENROLL } from "../graphql/Mutation";
import { GET_CHECK_FEES, GET_ENROLL, GET_BORING } from "../graphql/Query";
import swal from "sweetalert";
import degif from "../Images/Degree course.gif";

export default function NoMoreBoringCourses() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [validatedEdit, setValidatedEdit] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [qualification, setQualification] = useState("");
  const [course, setCourse] = useState("");

  const [validateFees, setValidateFees] = useState(false);
  const [feesName, setFeesName] = useState("");
  const [number, setNumber] = useState("");
  const [courseName, setCourseName] = useState("");

  const [createEnroll] = useMutation(MUTATION_ENROLL, {
    refetchQueries: [GET_ENROLL],
  });

  const [createCheckFees] = useMutation(MUTATION_CHECK_FEES, {
    refetchQueries: [GET_CHECK_FEES],
  });

  const { data: noneData } = useQuery(GET_BORING);

  function handleSubmitEnroll(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidatedEdit(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidatedEdit(false);
      event.preventDefault();
      createEnroll({
        variables: {
          enrollInput: {
            name: `${name}`,
            contact: `${contact}`,
            qualification: `${qualification}`,
            course: `${course}`,
          },
        },
      });
      swal({
        title: "Thank you for enquiring ScreenWork Multimedia,",
        text: "we will get back to you soon",
        icon: "success",
      });
      setName("");
      setContact("");
      setQualification("");
      setCourse("");
      handleClose();
    }
  }

  function handleSubmitCheckFees(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidateFees(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidateFees(false);
      event.preventDefault();
      createCheckFees({
        variables: {
          checkFeesInput: {
            name: `${feesName}`,
            number: `${number}`,
            courseName: `${courseName}`,
          },
        },
      });
      swal({
        title: "Thank you for enquiring ScreenWork Multimedia,",
        text: "we will get back to you soon",
        icon: "success",
      });
      setFeesName("");
      setNumber("");
      setCourseName("");
      handleClose1();
    }
  }

  const settings = {
    className: "center",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: function (index) { },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function editEnroll(ddta) {
    setCourse(ddta);
    handleShow();
  }

  function checkFees(data) {
    setCourseName(data);
    handleShow1();
  }

  return (
    <>
      <Container style={{ marginTop: 50, padding: "15px" }} fluid>
        <Row style={{ textAlign: "center", marginBottom: 30 }}>
          <h2 className="headingH3" style={{ fontWeight: "bold" }}>
            <FaQuoteLeft style={{ color: "#ffb606", marginTop: "-30px" }} /> No More Boring Degree Courses <FaQuoteRight className="nomoreicon" />
          </h2>
        </Row>
        <div>
          <Container style={{ marginTop: "30px", marginBottom: 50 }} fluid>
            <Row style={{ marginTop: "10px" }}>
              <Col md={4}>
                <Image className="mx-auto d-block d-none d-lg-block" src={degif} style={{ height: "90%", width: "90%" }}></Image>
              </Col>
              <Col md={8}>
                <Slider {...settings}>
                  {
                    noneData && noneData.getNoBoring.map((cData) => (
                      <div>
                        <Card style={{ borderWidth: "0px" }} className="Nomorboringcard">
                          <Card.Body>
                            <h2 style={{ color: "#002147", fontWeight: "bold", fontFamily: "DM Sans" }}>{cData.courseName}</h2>
                            <p style={{ marginTop: 10, fontWeight: "bold", fontSize: 20, color: " #ffb606", fontFamily: "DM Sans" }}>About this Course</p>
                            <p style={{ color: " #666666", fontWeight: "bold", fontFamily: "DM Sans", fontSize: "13px" }}>{cData.description}</p>
                            <p style={{ marginTop: 20, fontWeight: "bold", fontSize: 15, color: "#002147", fontFamily: "DM Sans" }}>LEARNING OUTCOMES</p>
                            {
                              cData.points.map((outData) => (
                                <p style={{ marginTop: 10, fontWeight: 400, fontSize: "13px", color: " #666666", fontFamily: "DM Sans" }}>
                                  <FaCheckDouble style={{ color: "#ffb606" }} /> {outData}
                                </p>
                              ))
                            }
                            <Col>
                              <Button style={{ maxWidth: "50%", minWidth: "37%", height: 50, color: "#fff", fontWeight: "bold", marginTop: 10, fontFamily: "DM Sans" }} variant="warning" onClick={() => editEnroll(cData.courseName)}>Enroll Now</Button>
                              <Button style={{ maxWidth: "50%", minWidth: "37%", height: 50, color: "#fff", fontWeight: "bold", marginTop: 10, fontFamily: "DM Sans", backgroundColor: "#3498db", marginLeft: 10 }} onClick={() => checkFees(cData.courseName)}>Check Fees</Button>
                            </Col>
                          </Card.Body>
                        </Card>
                      </div>
                    ))
                  }
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedEdit} onSubmit={handleSubmitEnroll}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01" style={{ marginTop: "10px" }}>
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} value={name} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02" style={{ marginTop: "10px" }}>
                <Form.Label>Phone</Form.Label>
                <Form.Control required type="number" placeholder="Enter Phone No." onChange={(e) => setContact(e.target.value)} value={contact} />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: "15px" }}>
                <Form.Label>Qualification</Form.Label>
                <Form.Control required as="textarea" placeholder="Enter Your Qualification" onChange={(e) => setQualification(e.target.value)} value={qualification} />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: "15px" }}>
                <Form.Label>Course</Form.Label>
                <Form.Control required type="text" placeholder="Enter Course" value={course} disabled />
              </Form.Group>
            </Row>
            <Button className="mx-auto d-block" type="submit" variant="warning" style={{ width: "37%", height: 40, color: "#fff", fontWeight: "bold", marginTop: 10, fontFamily: "DM Sans" }}>Apply Now</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validateFees} onSubmit={handleSubmitCheckFees}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01" style={{ marginTop: "10px" }}>
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Name" onChange={(e) => setFeesName(e.target.value)} value={feesName} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02" style={{ marginTop: "10px" }}>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control required type="number" placeholder="Enter Mobile No." onChange={(e) => setNumber(e.target.value)} value={number} />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: "15px" }}>
                <Form.Label>Course Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Course" value={courseName} disabled />
              </Form.Group>
            </Row>
            <Button className="mx-auto d-block" type="submit" variant="warning" style={{ width: "37%", height: 40, color: "#fff", fontWeight: "bold", marginTop: 10, fontFamily: "DM Sans" }}>Check Fees</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
