
import React from 'react'
import { Container, Row, Col, Card, Image, Button, Carousel } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../ComponentsCss/AffliationSlider.css'
import cardpic from '../Images/f3.jpg'

import pic1 from '../Images/client/1.jpg'
import pic2 from '../Images/client/2.jpg'
import pic3 from '../Images/client/3.jpg'
import pic4 from '../Images/client/4.jpg'
import pic5 from '../Images/client/5.jpg'
import pic6 from '../Images/client/6.jpg'
import pic7 from '../Images/client/7.jpg'
import pic8 from '../Images/client/8.jpg'
import pic9 from '../Images/client/9.jpg'
import pic10 from '../Images/client/10.jpg'
import pic11 from '../Images/client/11.jpg'
import pic12 from '../Images/client/12.jpg'
import pic13 from '../Images/client/13.jpg'
import pic14 from '../Images/client/14.jpg'
import pic15 from '../Images/client/15.jpg'
import pic16 from '../Images/client/16.jpg'
import pic17 from '../Images/client/17.jpg'
import pic18 from '../Images/client/18.jpg'
import pic19 from '../Images/client/19.jpg'
import pic20 from '../Images/client/20.jpg'
import pic21 from '../Images/client/21.jpg'
import pic22 from '../Images/client/22.jpg'




export default function AffliationSlider() {
    const settings = {

        className: "center",
        infinite: true,
        slidesToShow: 5,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 1000,

        afterChange: function (index) {

        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>

            <Container style={{ marginTop: '30px' }}>
                <Slider {...settings}>
                    <div>
                        <Image src={pic1} className='img-fluid' style={{height:'120px',width:"280px"}} />
                    </div>
                    <div>
                        <Image src={pic2} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic3} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic4} className='img-fluid ' style={{height:'100px',marginTop:'10px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic5} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic6} className='img-fluid ' style={{height:'100px',width:"280px"}} />
                    </div>
                    <div>
                        <Image src={pic7} className='img-fluid ' style={{height:'100px',width:"280px"}} />
                    </div>
                    <div>
                        <Image src={pic8} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic9} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic10} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic11} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic12} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic13} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic14} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic15} className='img-fluid ' style={{height:'100px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic16} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic17} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic18} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic19} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic20} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic21} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                    <div>
                        <Image src={pic22} className='img-fluid ' style={{height:'120px',width:"280px"}}/>
                    </div>
                </Slider>
            </Container>
        </div>
    )
}

