import React, { useState } from 'react'
import { Col, Container, Row, FormControl, Image, Button, Form } from 'react-bootstrap'
import contactpic from '../Images/contact.png'
import '../ComponentsCss/StudentEnquiry.css'
import { MUTATION_CHECK_FEES, MUTATION_ENROLL } from '../graphql/Mutation';
import { GET_CHECK_FEES, GET_ENROLL, GET_NONE_OF_THESE } from '../graphql/Query';
import swal from 'sweetalert';
import { useMutation, useQuery } from '@apollo/client';
import bgimg2 from '../Images/ccfbg.jpg';


export default function StudentEnquiry() {
    let count = 1
    const [validateFees, setValidateFees] = useState(false)
    const [feesName, setFeesName] = useState("")
    const [number, setNumber] = useState("")
    const [courseName, setCourseName] = useState("")

    const { data: noneData } = useQuery(GET_NONE_OF_THESE);

    const [createCheckFees] = useMutation(MUTATION_CHECK_FEES, {
        refetchQueries: [
            GET_CHECK_FEES
        ]
    })

    function handleSubmitCheckFees(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidateFees(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidateFees(false);
            event.preventDefault();
            createCheckFees({
                variables: {
                    "checkFeesInput": {
                        "name": `${feesName}`,
                        "number": `${number}`,
                        "courseName": `${courseName}`
                    }
                }
            })
            swal({
                title: "Thank you for enquiring",
                text: "we will get back to you soon",
                icon: "success",

            })
            setFeesName('')
            setNumber('')
            setCourseName('')

        }
    }

    return (
        <Container style={{ backgroundImage: `url(${bgimg2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', padding: '20px 20px 20px 60px', marginTop: 40, height: '100%', width: '100%' }} fluid>
            <Container>
                <Row>
                    <Col md={6} style={{ textAlign: 'left', marginTop: 4 }}>
                        <h3 style={{ color: '#ffffff', fontWeight: 'bold' }}>CHECK COURSE FEES</h3>
                        <Form noValidate validated={validateFees} onSubmit={handleSubmitCheckFees}>
                            <Row>
                                <Col md={12}>
                                    <FormControl required placeholder="Name" type="text" style={{ height: '40px', width: '70%' }} onChange={(e) => setFeesName(e.target.value)} value={feesName} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ marginTop: 15 }}>
                                    <FormControl required type="number" placeholder="Mobile No." style={{ height: '40px', width: '70%' }} onChange={(e) => setNumber(e.target.value)} value={number} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{ marginTop: 15 }}>
                                    <Form.Select required aria-label="Default select example" style={{ height: '40px', width: '70%' }} type='text' onChange={(e) => setCourseName(e.target.value)} value={courseName} >
                                        <option value="" selected="selected" disabled="disabled"> Select Course Name </option>
                                        {
                                            noneData && noneData.getNoneofThese.map(cData =>
                                                <option value={cData.courseName}>{count++}. {cData.courseName}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Button style={{ maxWidth: '50%', minWidth: '30%', height: 40, color: '#fff', fontWeight: 'bold', marginTop: 40 }} variant="warning" type='submit'>Check Fees</Button>{' '}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
