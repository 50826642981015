import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function LocationMap() {
  return (
    <>
    <Container fluid>
    <Row>
        <Col md={12}>
        <div >
       <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7297.959269828296!2d78.7915689!3d23.8548569!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3978d134e6f8aa0b%3A0x1ee63782c772f8c!2sScreenWork%20Institute%20of%20Animation%20%26%20Multimedia%20(Run%20by%20Shri%20Mahesh%20Sarvvikash%20Samaj%20Uthhan%20Samiti)!5e0!3m2!1sen!2sin!4v1677670090350!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: '100%', padding: 20, height: 350 }}></iframe>
        </div>

        </Col>
    </Row>
    </Container>
      
    </>
  )
}
