import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner, Table, Card, Image, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client';
import { GET_TESTIMONIAL } from '../../graphql/Query'
import { MUTATION_DELETE_TESTIMONIALS, MUTATION_TESTIMONIAL } from '../../graphql/Mutation';
import swal from 'sweetalert';
import uniqid from 'uniqid';
import AWS from 'aws-sdk';
import { FaTrashAlt } from "react-icons/fa";
const ID = "AKIA6GB4RFKTM63VVHEK";
const SECRET = "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";
const BUCKET_NAME = "screenwork-bucket";


const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const imageKey = []

console.log(imageKey)
export default function AdTestimonial() {

  const { data, loading } = useQuery(GET_TESTIMONIAL, {
    pollInterval: 300
  })

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false)
  const [name, setName] = useState("")
  const [discription, setDiscription] = useState("")
  const [designation, setDesignation] = useState("")
  const [companyname, setCompanyName] = useState("")
  const [file, setFile] = useState("")
  const [uploadDone, setUploadDone] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [selectImage, setSelectImage] = useState("")
  const [multipleImgKey, setMultipleImgKey] = useState([])
  const [imageBox, setImageBox] = useState()

  const [createTestimonial] = useMutation(MUTATION_TESTIMONIAL, {
    refetchQueries: [
      GET_TESTIMONIAL
    ]
  })
  const [testId, setTestId] = useState()
  const [deleteTestimonials, { loading: deleteLoading }] = useMutation(MUTATION_DELETE_TESTIMONIALS, {
    refetchQueries: [
      GET_TESTIMONIAL
    ]
  })

  function dltHandle(id) {
    setTestId(id)
    handleShow()
  }

  function delteTestimonial() {
    deleteTestimonials({
      variables: {
        "testId": `${testId}`
      }
    })
    swal({
      title: "Done",
      text: "Testimonial Delete Successfully",
      icon: "success",
    })
    handleClose()
  }


  console.log("selectImage", selectImage)

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      setValidated(false);
      event.preventDefault();
      try {
        const uniqueId = uniqid();
        var regex = new RegExp('[^.]+$');
        var extension = selectImage.name.match(regex);
        var uniqueFileName = "Tesimonial" + uniqueId + '-' + uniqueId + '.' + extension[0];
        setImageBox(uniqueFileName)
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueFileName,
          Body: selectImage,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            createTestimonial({
              variables: {
                "testimonialInput": {
                  "name": `${name}`,
                  "discription": `${discription}`,
                  "designation": `${designation}`,
                  "companyname": `${companyname}`,
                  "profileImage": `${uniqueFileName}`
                }
              }
            })
            swal({
              title: "Added",
              text: "Your Profile is Updated",
              icon: "success",
            })
          }
          setName('')
          setDiscription('')
          setDesignation('')
          setCompanyName('')
          setFile('')
          setMultipleImgKey([])
          setSelectImage('')
          setUploadLoading(false)
          setUploadDone(true)
        });
      }
      catch (err) {
        swal({
          title: 'Error!!!',
          text: 'Error please try again',
          icon: 'error',
        });
        console.log(err);
        setUploadLoading(false)
      }
    }
  };


  return (
    <>
      <Container fluid>
        {/* <h3 style={{ textAlign: 'center' }}>Add Testimonial</h3> */}
        <h3 style={{ textAlign: 'center' }}>TESTIMONIALS</h3>
        <hr color='black'></hr>
        <Row>
          <Col md={4}>
            <Card style={{ padding: '20px' }}>
              <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Add Testimonial</h5>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Control type="text" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} value={name} style={{ marginTop: 20, fontSize: 12 }} />
                <Form.Control as="textarea" placeholder="Enter Description" onChange={(e) => setDiscription(e.target.value)} value={discription} style={{ marginTop: 20, fontSize: 12 }} />
                <Form.Control type="text" placeholder="Enter Designation" onChange={(e) => setDesignation(e.target.value)} value={designation} style={{ marginTop: 20, fontSize: 12 }} />
                <Form.Control type="text" placeholder="Enter Company name" onChange={(e) => setCompanyName(e.target.value)} value={companyname} style={{ marginTop: 20, fontSize: 12 }} />
                <Form.Control type="file" placeholder="Enter Company name" onChange={(e) => setSelectImage(e.target.files[0])} multiple accept="image/*" style={{ marginTop: 20, fontSize: 12 }} />
                <Button className='mx-auto d-block' type="submit" style={{ marginTop: '10px', borderRadius: '0px', fontSize: 12 }}>Upload</Button>
              </Form>
            </Card>
          </Col>
          <Col md={8}>
            <Table bordered style={{ fontSize: 12 }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Designation</th>
                  <th>Company</th>
                  <th>Image</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ?
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    data && data.getTestimonial.slice(0).reverse().map(userData =>

                      <tr>
                        <td>{userData.name}</td>
                        <td>{userData.discription}</td>
                        <td>{userData.designation}</td>
                        <td>{userData.companyname}</td>
                        <td><Image src={`https://screenwork-bucket.s3.ap-south-1.amazonaws.com/${userData.profileImage}`} style={{ height: '100px', width: '100px' }}></Image></td>
                        <td><FaTrashAlt onClick={() => dltHandle(userData.id)} style={{ color: 'red' }} /></td>
                      </tr>
                    )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ fontSize: 20 }}>Are You Sure!!!! You want to delete this testimonial?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: '#e74c3c', border: 'none' }} onClick={() => delteTestimonial()} >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
