import { useQuery } from '@apollo/client'
import React from 'react'
import { Container, Spinner, Table } from 'react-bootstrap'
import { GET_CHECK_FEES } from '../../graphql/Query'
import Moment from 'react-moment';

export default function CheckCourseRequest() {

    const { data, loading } = useQuery(GET_CHECK_FEES, {
        pollInterval: 300
    })

    return (
        <>
            <Container fluid>
                <h3 style={{ textAlign: 'center' }}>COURSE REQUESTS</h3>
                <hr color='black'></hr>
                {
                    loading ?
                        <Spinner animation="border" role="status" className='mx-auto d-block'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> :
                        <Table bordered style={{ fontSize: 12 }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Course Name</th>
                                    <th>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> :
                                        data && data.getCheckFees.slice().reverse().map(userData =>
                                            <tr>
                                                <td>{userData.name}</td>
                                                <td>{userData.number}</td>
                                                <td>{userData.courseName}</td>
                                                <td> <Moment format="DD/MM/YYYY hh:mm a">{userData.createdDateTime}</Moment></td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                }

            </Container>
        </>
    )
}
