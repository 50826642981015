import { gql } from '@apollo/client'


export const ADMIN_LOGIN = gql`
mutation Mutation($userName: String, $password: String) {
  adminLogin(userName: $userName, password: $password) {
    adminId
    adminToken
    adminTokenExpire
  }
}
`

export const MUTATION_GALLERY = gql`
mutation Mutation($galleryInput: galleryInput) {
    createGallery(GalleryInput: $galleryInput) {
      tittle
      imageKey
      description
      category
      createdDateTime
      status
    }
  }
`

export const MUTATION_PLACEMENT = gql`
mutation Mutation($placementInput: placementInput) {
    createPlacement(PlacementInput: $placementInput) {
      name
      description
      courseName
      placementClgName
      placementYear
      placementPosition
      createdDateTime
      status
    }
  }
`
export const MUTATION_COURSE = gql`
mutation Mutation($courseInput: courseInput) {
    createCourse(CourseInput: $courseInput) {
      id
      courseName
      description
      points
      tag
      createdDateTime
      status
    }
  }
`
export const MUTATION_UPDATECOURSE = gql`
mutation Mutation($courseInput: courseInput) {
  updateCourse(CourseInput: $courseInput) {
    id
    courseName
    description
    points
    tag
    createdDateTime
    status
  }
}
`
export const DELETE_COURSE = gql`
mutation Mutation($courseId: ID) {
  deleteCourse(courseId: $courseId) {
    id
  }
}
`
export const MUTATION_EVENT = gql`
mutation Mutation($eventInput: eventInput) {
    createEvent(EventInput: $eventInput) {
      id
      title
      description
      eventDate
      eventStartTime
      eventEndTime
      venue
      createdDateTime
      status
    }
  }
`
export const MUTATION_ENROLL = gql`
mutation Mutation($enrollInput: enrollInput) {
  createEnroll(EnrollInput: $enrollInput) {
    id
    name
    contact
    qualification
    course
    createdDateTime
    status
  }
}
`
export const MUTATION_TESTIMONIAL = gql`
mutation Mutation($testimonialInput: testimonialInput) {
  createTestimonial(TestimonialInput: $testimonialInput) {
    id
  
  }
}
`
export const MUTATION_CHECK_FEES = gql`
mutation Mutation($checkFeesInput: checkFeesInput) {
  createCheckFees(CheckFeesInput: $checkFeesInput) {
    id
    name
    number
    courseName
    createdDateTime
    status
  }
}
`
export const MUTATION_DELETE_TESTIMONIALS = gql`
mutation DeleteTestimonials($testId: ID) {
  deleteTestimonials(testId: $testId) {
    id
    name
    discription
    designation
    companyname
    image
    createdDateTime
    profileImage
    status
  }
}
`

export const MUTATION_CHECK_CONTACTREQUEST = gql`
mutation Mutation($contactrequestInput: contactrequestInput) {
  createContactRequest(ContactrequestInput: $contactrequestInput) {
    id
    name
    email
    message
    createdDateTime
    status
  }
}
`
export const MUTATION_CREATE_CONTACTUS = gql`
mutation Mutation($contactusInput: contactusInput) {
  createContactus(ContactusInput: $contactusInput) {
    id
  }
}
`